import { AuthUser } from "../models/authUserModel";

export const getUserAdapter = (user: any) => ({
    name: user.data.name,
    gender: user.data.gender,
    status: user.data.status
});

export const getAuthUserAdapter = (response: any): AuthUser => ({
    token: response.access_token,
    rft: response.refresh_token,
    name: response.name,
    avatar: response.avatar,
    realm: response.realm || '',
    last_name: response.last_name,
    realm_roles: response.realm_roles
})