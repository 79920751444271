import React, {memo} from 'react'
import './CardPopular.scss'
import { PopularCities } from '../../models/PropertyModel';
import { AppStore } from '../../redux/store';
import { useSelector } from 'react-redux';

const CardPopular = memo(({ city_name, location_id, location_type, location_zoom, qty, url_cover }:PopularCities) => {
    const { country } = useSelector((store: AppStore) => store.country);
    const handleDetail = () => {
          if (typeof window !== 'undefined') {
            window.open(`/app/${country}/marketplace?location_id=${location_id}&location_type=${location_type}&zoom_search=${location_zoom}`, '_self')
          }
          
      };
    return(<div className='card-popular' onClick={handleDetail}>
        <div className='image w-1/3'>
            <img src={url_cover} alt="" />
        </div>
        <div className='content w-2/3'>
            <div className='h-auto'>
                <h1 className='font-medium text-sm lg:text-base text-graySoft truncate'>{city_name}</h1>
            </div>
            <div className='flex justify-between h-auto'>   
                <p className=' font-normal text-xs lg:text-sm text-gray200'>{qty} propiedades</p>
                
            </div>

        </div>
    </div>)
})

CardPopular.displayName = 'CardPopular'
export default CardPopular