import { useCallback, useEffect, useState } from 'react';
import arrow_button from "../../assets/icons/arrow_button.svg";
import delete_img from "../../assets/icons/delete.svg";
import * as yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';

import FormControlError from '../Controls/FormControlError'
import bank from "../../assets/icons/payments/cusca.png";
import { transferPaymentEndpoint, transferSalePaymentEndpoint } from '../../pages/Tenant/services/tenantService';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import { logoutAction } from '../../redux/features/auth';
import { getAuthToken, unsetAuth } from '../../helpers/authHelper';
import { AppStore } from '../../redux/store';
import { useToast } from '../ToastAlerts';
import { transferInvestorPaymentEndpoint } from '../../pages/Investor/services/investorService';
import { getTransferPaymentAccount } from '../../helpers/paymentHelper';
import { TransferInfo } from '../../pages/Investor/models/investorModel';
import { getTranferInfo } from '../../services/transferService';
import country from '../../redux/features/country';

interface Props {
  contract_id: string;
  transaction_detail_id: string;
  realm: string;
  typeTransfer: string;
  projectId?: string;

}

const TransferPayment = ({
  contract_id = "", transaction_detail_id = "", realm = "",
  typeTransfer = 'rent', projectId
}: Props) => {
  const { country } = useSelector((store: AppStore) => store.country);
  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user);
  const [formErrors, setFormErrors] = useState({
    id_transfer: false,
    dui: false,
    name: false,
  });
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [myFiles, setMyFiles] = useState<Array<any>>([]);
  const [imageAccepted, setImageAccepted] = useState<any>();
  const [error, setError] = useState("");
  const [form, setForm] = useState<any>({});
  const { callEndpoint } = useCallApiAndLoad();
  const formSchema = yup.object().shape({
    id_transfer: yup.string().required(),
    dui: yup.string().required(),
    name: yup.string().required(),
  });
  const [sendButton, setSendButton] = useState<boolean>(false)
  const [transferData, setTransferData] = useState<TransferInfo>()
  const toast: any = useToast();
  // Tranfer info

  useEffect(() => {
    const loadTransferInfo = async () => {
      let id = projectId ? `project_id=${projectId}` : null
      const { status, data } = await callEndpoint(
        getTranferInfo(typeTransfer, id)
      )

      if (status === 200) { 
        const response: any = fetchResponseAdapter(data)
        setTransferData(response.data)
      }
    }

    loadTransferInfo().catch((e) => console.error(e))
  }, [])

  const handleChange = ({ target }: any) => {
    const value =
      target.type === "checkbox"
        ? target.checked
        : target.value || target.label;
    if (target.name in formErrors) {
      yup
        .reach(formSchema, target.name)
        .isValid(value)
        .then((isValid: boolean) => {
          setForm((state: any) => ({
            ...state,
            [target.name]: value,
          }));
          setFormErrors((state: any) => ({
            ...state,
            [target.name]: !isValid,
          }));
        })
        .catch((e: any) => {
          console.error(e);
        });
    } else {
      setForm((state: any) => ({
        ...state,
        [target.name]: value,
      }));
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: any) => {
      setError("");
      setImageAccepted(acceptedFiles);
      setForm((state: any) => ({
        ...state,
        file: acceptedFiles[0],
      }));
      setMyFiles([
        ...acceptedFiles.map((file: any) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        ),
      ]);
    },
    [myFiles, imageAccepted]
  );

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
    onDrop,
  });

  const removeFile = (file: any) => {
    const newFiles = [...myFiles];
    newFiles.splice(newFiles.indexOf(file), 1);
    setMyFiles(newFiles);
  };

  const handleTransfer = async () => {
    formSchema
      .validate(form, { abortEarly: false })
      .then((values) => {
        let formData = new FormData();
        formData.append("identifier", form?.id_transfer);
        formData.append("document", form?.dui);
        formData.append("name", form?.name);
        formData.append("voucher", form?.file);
        if(realm === 'landlord') {
          return callEndpoint(
            transferInvestorPaymentEndpoint(formData, token, contract_id, transaction_detail_id)
          )
        }
        if(realm === 'tenant') {
          return callEndpoint(
            transferPaymentEndpoint(formData, token, contract_id, transaction_detail_id)
          )
        }
        if (realm === 'sales') {
          return callEndpoint(
            transferSalePaymentEndpoint(formData, token, contract_id, transaction_detail_id)
          )
        }
      })
      .then((res: any) => {
        setSendButton(true)
        const { status = 0, data } = res
        const response = fetchResponseAdapter(data)
        if (status === 401) {
          setSendButton(false)
          dispatch(logoutAction({}));
          unsetAuth();
          return navigate(`/app/${country}/auth/login`);
        } else if (status === 201) {
          setSendButton(false)
          if(realm === 'landlord') {
            navigate(`/app/${country}/l/ld/payment-completed`)
          } else if (realm === "sales") {
            navigate(`/app/${country}/l/tn/transfer-sales-completed`)
          } else {
            navigate(`/app/${country}/l/tn/transfer-completed`)
          }
        } else {
          setSendButton(false)
          toast.open(`(${response.code || 0}) Ocurrió un error al momento de envíar comprobante`, "error");
        }
      })
      .catch((e) => {
        e.inner.forEach((item: { message: any; path: any }) => {
          setFormErrors((state: any) => ({
            ...state,
            [item.path]: true,
          }));
        });
      });
  };

  const files = myFiles.map((file) => (
    <div
      className="flex justify-between items-center my-2 border-gray500 border rounded-md p-2"
      key={file.path}
    >
      <p className="text-sm text-gray-500">
        {file.path} - {file.size} bytes{" "}
      </p>
      <button onClick={() => removeFile(file)}>
        <img className="" src={delete_img} alt="" />
      </button>
    </div>
  ));

  return (
    <div className="w-full my-6">
      <div className="w-full text-graySoft ">
        Depositar a cuenta de
      </div>
      <div className="w-full my-4">
        <div className="w-full">
          <img className="w-48" src={transferData?.bank_logo} alt="" />
        </div>
        <div className="text-graySoft mt-3">
          <span className=' font-bold'>Tipo de cuenta: </span>  
          { transferData?.bank_account_type }
        </div>
        <div className="text-graySoft mt-3">
          <span className=' font-bold'>No. de cuenta: </span>  
          { transferData?.bank_account }
        </div>
        <div className="text-graySoft mt-3">
          <span className=' font-bold'>No. de documento: </span>  
          { transferData?.document }
        </div>
        <div className="text-graySoft mt-3">
          <span className=' font-bold'>Nombre del beneficiario: </span>  
          { transferData?.bank_headline }
        </div>
        
        <div className="text-graySoft mt-3">
          <span className=' font-bold'>Correo electrónico: </span>  
          { transferData?.email }
        </div>
      </div>
      <hr />
      <form className="mt-5" action="">
        <div className="w-full">
          <div>
            <label className="text-graySoft font-semibold">
              Número de referencia
            </label>
            <input
              type="text"
              name="id_transfer"
              onChange={handleChange}
              className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 "
            />
            {formErrors.id_transfer ? (
              <FormControlError
                message={
                  "El identificador de la transacción es requerido"
                }
              />
            ) : null}
          </div>
          <div className="my-4">
            <label className="text-graySoft font-semibold">
              Número de documento de identidad
            </label>
            <input
              type="text"
              name="dui"
              onChange={handleChange}
              className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 "
            />
            {formErrors.dui ? (
              <FormControlError
                message={"El DUI es requerido"}
              />
            ) : null}
          </div>
          <div>
            <label className="text-graySoft font-semibold">
              Nombre
            </label>
            <input
              type="text"
              name="name"
              onChange={handleChange}
              className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 "
            />
            {formErrors.name ? (
              <FormControlError
                message={"El nombre es requerido"}
              />
            ) : null}
          </div>
          <div className=" mt-4">
            <label className=" text-graySoft font-semibold ">
              Sube foto de comprobante (opcional)
            </label>
            <div className="text-graySoft mt-2">
              <div
                {...getRootProps({
                  className:
                    "dropzone flex flex-col py-8 px-4 w-full justify-center items-center",
                })}
              >
                <input {...getInputProps()} />
                <p className=" text-grayText font-normal text-base">
                  Arrastra tu archivo o{" "}
                  <span className=" text-primary font-semibold">
                    Selecciona
                  </span>{" "}
                </p>
                <span className="text-gray-500 text-xs">
                  Formatos soportados: JPEG y PNG
                </span>
                <span className="text-gray-500 text-xs">
                  Máximo de 500KB tamaño del archivo.
                </span>
              </div>
              <div className="mt-2">
                <div className="w-full mt-4">{files}</div>
              </div>
              <FormControlError message={error} />
            </div>
          </div>
          <div className="flex w-full my-6 justify-center">
            <button
              disabled={sendButton}
              type="button"
              onClick={handleTransfer}
              className={`${sendButton ? ' opacity-60' : ''} bg-[#212121] py-4 px-4 w-full text-white text-base rounded-lg flex justify-end items-center group`}
            >
              <span className="mx-auto">
                Enviar a verificación
              </span>
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default TransferPayment
