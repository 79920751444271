import React, { memo, useEffect, useState } from "react";
import { useInvestorProjectsUnit } from "./hooks/useInvestorsProject";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppStore } from "../../../../redux/store";
import { getAuthToken } from "../../../../helpers/authHelper";
import { setFiltersProjectsAction } from "../../../../redux/features/filtersProjects";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import "./scss/ProjectInvestor.scss";

import MainTemplate from "../../layout/MainLayout";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  IconChecklist,
  IconChevronRight,
  IconDownload,
  IconEyeCheck,
  IconHistory,
} from "@tabler/icons-react";
import { formatDistanceToNow, isValid, parse } from "date-fns";
import { es } from "date-fns/locale";

const ProjectInvestors = memo(() => {
  const { id = "" } = useParams();
  const [openDocuments, setOpenDocuments] = useState(false);
  const user = useSelector((store: AppStore) => store.auth);
  const [open, setOpen] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);
  const [currentDocumentPath, setCurrentDocumentPath] = useState("");
  const token = getAuthToken(user);
  const dev = useSelector((store: AppStore) => store.authDeveloper);
  const {
    detailProject,
    loading,
    getDataInvestirProjectBank,
    getDataDetailModel,
    investorBank,
  } = useInvestorProjectsUnit();

  useEffect(() => {
    getDataDetailModel(token, id);
    getDataInvestirProjectBank(token, id);
  }, []);

  const renderStatus = (param: string) => {
    switch (param) {
      case "available":
        return (
          <span className="bg-[#D5F0E3] text-primary px-2 py-1 text-xs font-medium rounded-full">
            Disponible
          </span>
        );
      case "prebooked":
        return (
          <span className="bg-[#FEE3D5] text-orange px-2 py-1 text-xs font-medium rounded-full">
            Pre-reservado
          </span>
        );
      case "taken":
        return (
          <span className="bg-[#DDDEE4] text-blueDark px-2 py-1 text-xs font-medium rounded-full">
            Reservada
          </span>
        );
      case "assigned":
        return (
          <span className="bg-blue700 px-3 py-2 text-xs font-medium text-white rounded-full">
            Asignada
          </span>
        );
      default:
        return "";
    }
  };
  const renderScoreColumn = (params: any) => {
    const score = params.value;
    let scoreColor = "";
    let scoreText = "";

    // Define los colores y el texto dependiendo del valor de score
    if (score >= 90) {
      scoreColor = "green";
      scoreText = "Alto";
    } else if (score >= 70) {
      scoreColor = "yellow";
      scoreText = "Medio";
    } else {
      scoreColor = "red";
      scoreText = "Bajo";
    }

    // Renderiza el termómetro visual con el color y el texto correspondiente
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: 20,
            height: 20,
            borderRadius: "50%",
            backgroundColor: scoreColor,
            marginRight: 5,
          }}
        />
        <span>{scoreText}</span>
      </div>
    );
  };
  const handleOpenDialog = () => {
    setOpenDocuments(true);
  };

  const columns: GridColDef[] = [
    {
      field: "investor_name",
      headerName: "Propietario",
      flex: 1,
      headerClassName: "test-colum",
      headerAlign: "center",
      cellClassName: "test-row",
      align: "center",
    },
    {
      field: "unit_name",
      headerName: "Unidad",
      flex: 1,
      headerClassName: "test-colum",
      headerAlign: "center",
      cellClassName: "test-row",
      align: "center",
    },
    {
      headerClassName: "test-colum",
      cellClassName: "test-row",
      headerAlign: "center",
      field: "status",
      headerName: "Estado",
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <div className="w-full">{renderStatus(params.value)}</div>
      ),
    },
    {
      field: "earnings",
      headerName: "Ganancias",
      flex: 1,
      headerClassName: "test-colum",
      headerAlign: "center",
      cellClassName: "test-row",
      align: "center",
    },
    {
      field: "debt_level",
      headerName: "Debt Level",
      flex: 1,
      headerClassName: "test-colum",
      headerAlign: "center",
      cellClassName: "test-row",
      align: "center",
    },
    {
      field: "debt",
      headerName: "Debt",
      flex: 1,
      headerClassName: "test-colum",
      headerAlign: "center",
      cellClassName: "test-row",
      align: "center",
    },
    {
      field: "score",
      headerName: "Score",
      type: "number",
      flex: 1,
      headerClassName: "test-colum",
      headerAlign: "center",
      cellClassName: "test-row",
      align: "center",
      renderCell: (params) => renderScoreColumn(params),
    },
    {
      field: "document",
      headerName: "Documentos",
      flex: 1,
      headerClassName: "test-colum",
      headerAlign: "center",
      cellClassName: "test-row",
      align: "center",
      renderCell: (params) => (
        <Button onClick={handleOpenDialog}>
          <IconChecklist color="#212121" />
        </Button>
      ),
    },
  ];
  const investorBankWithIds = investorBank.map(
    (investor: any, index: number) => ({
      ...investor,
      id: index + 1, // Utilizando el índice + 1 como ejemplo de identificador único
    })
  );

  const documentsData = [
    {
      re_entry_at: "02/10/2024",
      name: "Documento unico de identidad",
      number: "23575437893245",
      link: "https://example.com/document1",
      document_path:
        "https://storage.googleapis.com/47a148dd-assets/documents/d93d1283-1eb0-4176-9a62-090b6e2cbfbd/carlos%20s-garc%C3%ADa%20t-dcsvconsal.jpg",
    },
    {
      re_entry_at: "02/10/2024",
      name: "NIT",
      number: "NA",
      link: "https://example.com/document2",
      document_path:
        "https://storage.googleapis.com/47a148dd-assets/documents/d93d1283-1eb0-4176-9a62-090b6e2cbfbd/carlos%20s-garc%C3%ADa%20t-dcsvconsal.jpg",
    },
    {
      re_entry_at: "02/10/2024",
      name: "Constancia",
      number: "NA",
      link: "https://example.com/document2",
      document_path:
        "https://storage.googleapis.com/47a148dd-assets/documents/d93d1283-1eb0-4176-9a62-090b6e2cbfbd/carlos%20s-garc%C3%ADa%20t-dcsvconsal.jpg",
    },
    {
      re_entry_at: "02/10/2024",
      name: "Recibo de luz",
      number: "NA",
      link: "https://example.com/document2",
      document_path:
        "https://storage.googleapis.com/47a148dd-assets/documents/d93d1283-1eb0-4176-9a62-090b6e2cbfbd/carlos%20s-garc%C3%ADa%20t-dcsvconsal.jpg",
    },
    {
      re_entry_at: "02/10/2024",
      name: "Recibo de agua",
      number: "NA",
      link: "https://example.com/document2",
      document_path:
        "https://storage.googleapis.com/47a148dd-assets/documents/d93d1283-1eb0-4176-9a62-090b6e2cbfbd/carlos%20s-garc%C3%ADa%20t-dcsvconsal.jpg",
    },
    // Agrega más datos según sea necesario
  ];

  const updateDateTime = (updateDate: string) => {
    const parsedDate = parse(updateDate, "dd/MM/yyyy", new Date());
    if (isValid(parsedDate)) {
      return (
        <p className="text-sm font-bold text-primary flex justify-start items-center">
          {" "}
          Actualizacíon{" "}
          {formatDistanceToNow(parsedDate, {
            addSuffix: true,
            locale: es,
          })}
        </p>
      );
    }
  };

  const handleOpen = (documentPath: string) => {
    setCurrentDocumentPath(documentPath);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentDocumentPath("");
  };
  const handleOpenHistory = () => {
    setOpenHistory(true);
  };

  const handleCloseHistory = () => {
    setOpenHistory(false);
  };

  const downloadFile = async (documentPath: string) => {
    const response = await fetch(documentPath);
    const filenameSplit = documentPath.split("/");
    const fileName = filenameSplit[filenameSplit.length - 1];
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  return (
    <MainTemplate>
      <Dialog open={openDocuments} onClose={() => setOpenDocuments(false)}>
        <div className="w-full lg:w-[600px] px-6 py-6">
          <h1 className="font-semibold text-[#212121] text-2xl">
            Documentación
          </h1>
          <p className="text-base font-normal text-[#757575]">
            Detalle de documentos necesarios del propietario
          </p>
          <div className="w-full">
            {documentsData?.length > 0 &&
              documentsData?.map((document: any, index: number) => (
                <div
                  key={index}
                  className="w-full flex justify-between items-center border border-gray-300 rounded-lg my-2 py-2 px-2 gap-3"
                >
                  <div className="flex justify-start items-center gap-2 w-3/5">
                    <div className="w-full">
                      <p className="text-sm font-bold text-[#212121] truncate">
                        {document?.name}
                      </p>
                      {document.number !== "NA" && (
                        <p className="text-xs font-normal text-[#757575]">
                          #{document.number}
                        </p>
                      )}

                      {document?.re_entry_at &&
                        updateDateTime(document?.re_entry_at as string)}
                    </div>
                  </div>

                  <div className=" flex w-2/5 justify-between items-center">
                    {document?.document_path !== null ? (
                      <button
                        className="focus:outline-none border-none tooltipDev"
                        onClick={() => handleOpen(document?.document_path)}
                      >
                        <IconEyeCheck color="#212121" />
                        <span className="tooltiptext">Ver documento</span>
                      </button>
                    ) : (
                      <p>No disponible</p>
                    )}
                    {document?.document_path !== null ? (
                      <button
                        className="focus:outline-none border-none tooltipDev"
                        onClick={() => downloadFile(document.document_path)}
                      >
                        <IconDownload color="#212121" />
                        <span className="tooltiptext">Descargar</span>
                      </button>
                    ) : (
                      <></>
                    )}

                    <button
                      onClick={() => handleOpenHistory()}
                      className="focus:outline-none border-none tooltipDev"
                    >
                      <IconHistory color="#212121" />
                      <span className="tooltiptext">Historial</span>
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Dialog>
      <div className="w-full">
        <div className="container mx-auto">
          <div className="py-24">
            <div className=" px-4 py-4 rounded-lg mb-4">
              {!loading && (
                <div className="w-full flex flex-wrap items-center">
                  <div className="w-full lg:w-1/4">
                    <img
                      className=" object-cover object-center h-[200px] w-full rounded-lg"
                      src={detailProject?.cover}
                      alt=""
                    />
                  </div>
                  <div className="w-full lg:w-3/4 md:px-4">
                    <div className="lg:px-4 mt-4 lg:mt-0">
                      <p className=" font-normal text-sm lg:text-base text-[#757575]">
                        #{detailProject?.code}
                      </p>
                      <div className="w-full text-blackGrayScale text-2xl lg:text-3xl font-semibold text-left tracking-wide">
                        {!loading && detailProject?.name}
                      </div>
                      <p className="text-[#757575] text-base md:text-lg">
                        {detailProject?.address}
                      </p>
                    </div>
                    <div className="flex flex-wrap mt-4 gap-4">
                      <div className="py-1 px-4 border border-[#E0E0E0] rounded-full">
                        <p className="text-sm font-bold">Unidades totales</p>
                        <p className="text-xs text-left text-[#757575]">
                          {detailProject?.total_units}
                        </p>
                      </div>
                      <div className="py-1 px-4 border border-[#E0E0E0] rounded-full">
                        <p className="text-sm font-bold">
                          Fecha estimada de entrega
                        </p>
                        <p className="text-xs text-left text-[#757575]">
                          {detailProject?.estimate_built_at}
                        </p>
                      </div>
                      <div className="py-1 px-4 border border-[#E0E0E0] rounded-full">
                        <p className="text-sm font-bold">Disponible</p>
                        <p className="text-xs text-left text-[#757575]">
                          {detailProject?.total_available_units}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="w-full px-4 lg:px-0">
              <div className="mb-10 mt-6 w-full flex justify-between items-start lg:flex-row flex-col-reverse">
                <div className="w-full lg:w-auto">
                  <h1 className="w-full mt-6 mb-1 text-blackGrayScale text-2xl lg:text-2xl font-semibold text-left tracking-wide">
                    Datos de propietarios
                  </h1>
                  <p className=" text-lg font-medium text-gray600 mb-2">
                    Ve la información necesaria sobre los propietarios
                  </p>
                </div>

                <img className="w-40" src={dev?.company_logo} alt="logo bank" />
                
              </div>
              <div style={{ height: 500, width: "100%" }}>
                <DataGrid
                  rows={investorBankWithIds}
                  columns={columns}
                  loading={loading}
                  autoPageSize
                  getRowId={(row) => row.id} // Definiendo una función para obtener el identificador único de cada fila
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="document-modal-title"
        aria-describedby="document-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            bgcolor: "background.paper",
            p: 4,
            borderRadius: "10px",
            height: "50vh",
            overflowY: "auto",
            "@media (max-width: 780px)": {
              width: "90%",
              height: "70vh",
            },
          }}
        >
          <iframe
            src={currentDocumentPath}
            width="100%"
            height="100%"
            title="Document Viewer"
          />
        </Box>
      </Modal>
      <Modal
        open={openHistory}
        onClose={handleCloseHistory}
        aria-labelledby="document-modal-title"
        aria-describedby="document-modal-description"
      >
        <Box
          sx={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            bgcolor: "background.paper",
            p: 4,
            borderRadius: "10px",
          }}
        >
          <div className="w-full">
            <h1 className="font-semibold text-[#212121] text-xl mb-4">
              Historial
            </h1>
            <p className="text-sm inline-block text-[#757575] my-1">
              <IconChevronRight className=" inline-block" /> El documento fue
              actualizado aproximadamente hace 4 meses
            </p>
            <p className="text-sm inline-block text-[#757575] my-1">
              <IconChevronRight className=" inline-block" /> El document se
              actualiara dentro de 2 meses
            </p>
          </div>
        </Box>
      </Modal>
    </MainTemplate>
  );
});

ProjectInvestors.displayName = "ProjectInvestors";
export default ProjectInvestors;
