import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom"
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import Forbidden from '../../components/Forbidden';
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad"
import LandlordVerification from './components/LandlordVerification';
import TenantVerification from './components/TenantVerification';
import { checkVerficationEndpoint } from './services/VerifciationServices';
import { useSelector } from 'react-redux';
import { AppStore } from '../../redux/store';

const Verification = () => {
  const {country} = useSelector((state: AppStore) => state.country);
    
    const { id = '' } = useParams() 
    const { isLoading, callEndpoint } = useCallApiAndLoad()
    const [ verificatonInfo, setVerficationInfo ] = useState({
        realm: ''
    })

    useEffect(() => {
        const checkAccontVerificaton = async () => {
            const { status, data } = await callEndpoint(checkVerficationEndpoint(id))
            if(status === 200) {
                const response = fetchResponseAdapter(data)
                setVerficationInfo(response.data)
            }
        }
        checkAccontVerificaton()
            .catch(e => console.error(e))
    }, [id])

    if(isLoading){
        return <h1>Verificando...</h1>
    }

    if(!verificatonInfo.realm){
        return <Forbidden message='Lo sentimos, la acción que quiere realizar es inválida' />
    }

    if(verificatonInfo.realm === 'landlord'){
        return <LandlordVerification id={id} />
    }
    
    if(verificatonInfo.realm === 'tenant'){
        return <TenantVerification id={id} />
    }

    return <Forbidden message='Lo sentimos, la acción que quiere realizar es inválida' />

}

export default Verification
