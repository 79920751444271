import React, { memo, useEffect, useState } from "react";
import NavBarDev from "../../../components/NavBar/NavBarDev";
import LoadingComponent from "../../../components/Loading/Loading";

interface MainTemplateProps {
  children: React.ReactNode | JSX.Element | JSX.Element[] | React.ReactNode[];
}
const MainTemplate = memo(({ children }: MainTemplateProps) => {
  const [preLoad, setPreLoad] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setPreLoad(!preLoad);
    }, 2200);
  }, []);

  return (
    <>
      {!preLoad ? (
        <div className="layout">
          <NavBarDev />
          <div className="body">{children}</div>
        </div>
      ) : (
        <LoadingComponent />
      )}
    </>
  );
});

MainTemplate.displayName = "MainTemplate";
export default MainTemplate;
