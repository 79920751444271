import axios from "axios";
const loadAbort = () => {
  return new AbortController();
};
export const getDataProjects = (token: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/pre-sales/v1/developers/projects`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getDetailProject = (token: string, id: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/pre-sales/v1/developers/projects/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getDetailProjectUnits = (
  token: string,
  id: string,
  filters: any
) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/pre-sales/v1/developers/projects/${id}/units?${filters}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getBookedUsers = (
  token: string,
  project_id: string,
  unit_id: string
) => {
  const controller = loadAbort();
  // https://api.propilatam.dev/pre-sales/v1/developers/projects/%7Bproject_id%7D/units/%7Bunit_id%7D/lanlords-booked/
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/pre-sales/v1/developers/projects/${project_id}/units/${unit_id}/lanlords-booked`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};


export const getInvestorByProjects = (token: string, id: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/pre-sales/v1/developers/projects/${id}/investors`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

export const getInvestorByProjectsBank = (token: string, id: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/pre-sales/v1/developers/projects/${id}/investors/banking-analysis`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};


export const getDetailUserUnit = (token: string, idProject: string, idUnit: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios({
        method: "get",
        url: `${urlService}/pre-sales/v1/developers/projects/${idProject}/units/${idUnit}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }),
    controller,
  };
};

