import axios from "axios";

const loadAbort = () => {
  return new AbortController();
};

export const checkVerficationEndpoint = (verificationId: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios.get(
        `${urlService}/api/v1/auth/user/get-verification/${verificationId}`
      ),
    controller,
  };
};

export const verifyLandlord = (verificationId: string, code: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios.post(
        `${urlService}/api/v1/auth/landlord/verify/${verificationId}`,
        { code }
      ),
    controller,
  };
};

export const verifyTenant = (verificationId: string) => {
  const controller = loadAbort();
  return {
    call: (urlService: string) =>
      axios.post(`${urlService}/api/v1/auth/tenant/verify/${verificationId}`, {
        code: "-",
      }),
    controller,
  };
};
