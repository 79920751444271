import { useSelector } from "react-redux";
import { AppStore } from "../../redux/store";
import AccountCard from "./components/AccountCard/AccountCard";

const AccountLd = () => {
  const user = useSelector((store: AppStore) => store.auth);
  const {country} = useSelector((store: AppStore) => store.country);

  const optionsAccount: any = [{
    icon: 'user',
    title: 'Información personal',
    description: 'Proporciona tus datos personales e indícanos cómo podemos ponernos en contacto contigo',
    link: `/app/${country}/l/ld/profile`
  },{
    icon: 'buy',
    title: 'Mis pre-reservas',
    description: 'Revisa todos tus proceso de reserva dentro de la plataforma',
    link: `/app/${country}/l/ld/pre-booking`
  },
  {
    icon: 'offer',
    title: 'Mis inversiones',
    description: 'Da un vistazo a todas las propiedades en planos que haz comprado en Propi',
    link: `/app/${country}/l/ld/payments`
  }, 
  {
    icon: 'rent',
    title: 'Mis propiedades',
    description: 'Administra todas las propiedades que tienes en alquiler o en venta con nosotros. Acepta, rechaza o negocia las ofertas que recibes',
    link: `/app/${country}/l/properties`
  },
  {
    icon: 'help',
    title: 'Documentacion',  
    description: '¡Encuentra respuestas rápidas a tus preguntas! Explora FAQs y más para resolver tus dudas y sacar el máximo provecho de nuestra plataforma.',
    link: `/app/${country}/l/ld/documents`
  },
  {
    icon: 'help',
    title: 'Ayuda',
    description: '¡Encuentra respuestas rápidas a tus preguntas! Explora para resolver tus dudas y sacar el máximo provecho de nuestra plataforma',
    link: `/${country}/ayuda`
  }
]

  return (
    <div className="w-full pt-20 lg:pt-32 pb-14">
      <div className="container mx-auto">
          <div className="m-auto w-full px-4 lg:px-0">
          <h1 className="text-blackGrayScale text-3xl lg:text-3xl font-semibold text-left tracking-wide mb-10">
              Hola, {user?.name}
            </h1>
              <div className="w-full mt-6 mb-2 text-blackGrayScale text-2xl lg:text-2xl font-semibold text-left tracking-wide">
                Mi cuenta
              </div>
              <p className=" text-lg font-medium text-gray600 mb-2">
              {user?.name}, aqui podras visualizar las opciones de la plataforma
              </p>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mt-10 lg:mt-20 px-4 lg:px-12">
            {
              optionsAccount.length > 0 && optionsAccount.map((item: any, index: number) => (
                <AccountCard data={item} />
              ))
            } 
          </div>
      </div>

        
    </div>
  );
};

export default AccountLd;
