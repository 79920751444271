import React, { useRef, useEffect } from "react";
import {
  CardContainer,
  ContainerSliderDefault,
  CharacteristicsContainer,
  CloseCard,
} from "./styles";

const Card: React.FC<any> = ({ openInOtherTab = true, path, property, clickOutside }) => {
  const cardRef: any = useRef();
  const closeButtonRef: any = useRef();
  const getPath = (e: any) => {

    if (e.target !== closeButtonRef.current) {
      if (openInOtherTab) {
        window.open(path);
      }
      }
  };

  const handleClick = (e: any) => {
    if (!cardRef?.current?.contains(e.target) && clickOutside) {
      clickOutside(false);
    }
  };


  useEffect(() => {
    document.addEventListener("mousedown", handleClick, { passive: true });
    document.addEventListener("touchend", handleClick, { passive: true });
    return () => {
      document.removeEventListener("mousedown", handleClick);
      document.addEventListener("touchend", handleClick);
    };
  }, []);

  return (
    
    <CardContainer ref={cardRef} onClick={getPath}>
      <CloseCard ref={closeButtonRef} onClick={() => clickOutside(false)}>
        &times;
      </CloseCard>
      <ContainerSliderDefault>
        <img
          alt={property.property_code}
          src={property.cover}
          height="auto"
          width="100%"
        />
      </ContainerSliderDefault>
      <CharacteristicsContainer>
        <section className="py-3 px-3">
          <h1 className=" text-base font-semibold tracking-wide whitespace-nowrap text-ellipsis overflow-hidden text-[#212121] -mb-0">
            {`${property.property_name}`}
          </h1>
          <p className="text-sm font-normal text-[#757575] -mb-0">{property.property_city}</p>
          <div className="w-full flex justify-start items-center gap-2 -mb-0">
            {
              property?.characteristics?.length > 0 && property?.characteristics?.map((item: any, index: number) => (
                <>
                {item.id === "MT2" && (
                  <>
                  <p
                    key={index}
                    className="text-[#757575] text-normal text-sm"
                  >
                    {item.value} mt2
                  </p>
                  <span aria-hidden="true">&nbsp;·&nbsp;</span>
                  </>
                  
                  
                )}
                {item.id === "FBTH" && (
                  <>
                  <p
                    key={index}
                    className="text-[#757575] text-normal text-sm"
                  >
                    {item.value} baños
                  </p>
                  <span aria-hidden="true">&nbsp;·&nbsp;</span>
                  </>
                  
                )}
                {item.id === "RMS" && (
                  <p
                    key={index}
                    className="text-[#757575] text-normal text-sm"
                  >
                    {item.value} habitaciones
                  </p>
                )}
                </>
                
              ))
            }
          </div>
          <p className="text-sm font-semibold text-[#212121] -mb-0">{property?.property_price}</p>
        </section>
      </CharacteristicsContainer>
    </CardContainer>
    
  );
};

export default Card;
