import { useContext, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./FilterBar.scss";
// import filtericon from '../../../assets/icons/filter.svg';
import { ModalContext } from "../../context/ModalContext";
import { AppStore } from "../../../../redux/store";

import useCallApiAndLoad from "../../../../hooks/useCallApiAndLoad";
import { fetchResponseAdapter } from "../../../../adapters/fetchAdapter";
import { setFiltersAction } from "../../../../redux/features/filters";
import React from "react";
import { MainModalContext } from "../../../../context/MainModalContext";
import { searchData } from "../../../../services/publicService";
import { FiltersStore } from "../../../../models/PropertyModel";
import {
  cleanFormParams,
  replaceFormParams,
} from "../../helpers/paramsHelpers";
import { getTypePropertiesEndpoint } from "../../../Properties/services/PropertiesService";
import { createSearchParams, useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Fade } from "@mui/material";

import { IconBuilding, IconBuildingCommunity, IconBuildingSkyscraper, IconFilter, IconMapPin, IconMapPins, IconX } from "@tabler/icons-react";
import SelectMaxMinPrice from "./components/SelectMaxMinPrice/SelectMaxMinPrice";

interface PublishCatalogs {
  propertyTypes: Array<any>;
}

const FilterBar = () => {
  const { country } = useSelector((state: AppStore) => state.country);
  const [searchText, setSearchText] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [notResults, setNotResults] = useState(false);

  const navigate = useNavigate();

  const inputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  let { handleModal } = useContext(ModalContext);
  let { handleMainModal } = useContext(MainModalContext);
  const { callEndpoint } = useCallApiAndLoad();
  const [catalogs, setCatalogs] = useState<PublishCatalogs>({
    propertyTypes: [],
  });
  const filters = useSelector((store: AppStore) => store.filters);
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [selectedOptionSearch, setSelectedOptionSearch] = useState<
    string | null
  >(filters?.location_id !== null ? window.localStorage.getItem("city") : null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dataSearchHits, setDataSearchHits] = useState<any>([]);

  useEffect(() => {
    const callCatalogs = async () => {
      const propertyTypes = await callEndpoint(getTypePropertiesEndpoint());
      if (propertyTypes.status !== 200) {
        return;
      }
      const propertyTypesResponse = fetchResponseAdapter(propertyTypes.data);
      setCatalogs({
        propertyTypes: propertyTypesResponse.data,
      });
    };
    callCatalogs().catch((e) => console.error(e));
  }, []);

  const removeFilter = (filterName: string) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete(filterName);

    dispatch(setFiltersAction({ [filterName]: null }));
    window.history.replaceState(
      null,
      "",
      `${window.location.pathname}?${urlParams}`
    );
  };

  const handleSortFilter = (option: any) => {
    if (option) {
      dispatch(setFiltersAction({ sort: decodeURIComponent(option) }));

      const data = {
        sort: option,
      };

      const replaceParamsObject: any = {
        ...filters,
        ...data,
      };

      if (JSON.stringify(data) !== "{}") {
        const filterDataMapped: FiltersStore =
          replaceFormParams(replaceParamsObject);
        dispatch(setFiltersAction(filterDataMapped));
        const cleanedFilters = cleanFormParams(filterDataMapped);

        if (Object.keys(cleanedFilters).length > 0) {
          navigate(`?${createSearchParams(cleanedFilters)}`);
        }
      }
    }
  };

  // order
  const handleOptionSelect = (option: string, name: string) => {
    setSelectedOption(name);
    handleSortFilter(encodeURIComponent(option));
    setIsOpen(false);
  };

  const handleDropdownClick = () => {
    setIsOpen(!isOpen);
  };

  const options = [
    {
      name: "Más nuevo",
      value: "+recent",
    },
    {
      name: "Menor precio",
      value: "-price",
    },
    {
      name: "Mayor precio",
      value: "+price",
    },
  ];

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const value = event.target.value;

    setSelectedOptionSearch(null);
    if (event.target.value.length > 3) {
      setSearchText(value);
      seachDataElastic(event.target.value);
    } else {
      setDataSearchHits([]);
      setShowSuggestions(false);
      setNotResults(false);
    }

    // setShowSuggestions(value.trim().length > 0);
    // searchElasticsearch(event.target.value)
    // seachDataElastic(event.target.value)
  };

  const handleSuggestionClick = (
    suggestion: string,
    id: string,
    type: string,
    long: number,
    lati: number,
    zoomMap: number
  ) => {
    setSearchText(suggestion);
    setSelectedOptionSearch(suggestion as string);
    submitFilter(id, type, long, lati, zoomMap);
    window.localStorage.setItem("city", suggestion as string);
    setDataSearchHits([]);
  };

  const seachDataElastic = async (query: string) => {
    const queryData = {
      query: {
        match: {
          value: {
            query: query,
          }, // Reemplaza "campo" con el nombre del campo que deseas buscar en ElasticSearch
        },
      },
      sort: [
        {
          search_rank: {
            order: "desc",
          },
        },
      ],
    };
    const data = await callEndpoint(searchData(queryData));
    if (data?.status !== 200) {
      setShowSuggestions(true);
    }

    const filteredSuggestions = data?.data?.hits?.hits?.map(
      (hit: any) => hit._source
    );

    if (filteredSuggestions?.length === 0) {
      setNotResults(true);
    } else {
      setNotResults(false);
    }

    setDataSearchHits(filteredSuggestions);
  };

  const submitFilter = (
    idCity: string,
    typeCity: string,
    lng: number,
    lat: number,
    zoom: number
  ) => {
    const data = {
      location_id: idCity,
      location_type: typeCity,
      lat: lat || -89.18718,
      lng: lng || 13.68935,
      zoom_search: zoom || 10,
    };
    const replaceParamsObject: any = {
      ...filters,
      ...data,
    };
    if (JSON.stringify(data) !== "{}") {
      const filterDataMapped: FiltersStore =
        replaceFormParams(replaceParamsObject);
      dispatch(setFiltersAction(filterDataMapped));
      const cleanedFilters = cleanFormParams(filterDataMapped);
      if (Object.keys(cleanedFilters).length > 0) {
        navigate(`?${createSearchParams(cleanedFilters)}`);
      }
    }
  };

  const suggestionList = dataSearchHits?.length > 0 && (
    <ul className="suggestion-list hidden lg:block">
      {dataSearchHits.map((item: any) => (
        <li
          key={item.id}
          className={`suggestion flex justify-start items-center py-3 ${
            selectedOption === item.value ? "selected" : ""
          }`}
          onMouseDown={(e) => e.preventDefault()}
          onClick={() =>
            handleSuggestionClick(
              item.value,
              item.id,
              item.type,
              item?.location?.coordinates[0],
              item?.location?.coordinates[1],
              item?.location?.zoom
            )
          }
          data-value={item.value}
        >
          {item.type === "city" && (
           <IconBuildingSkyscraper className="mr-2" />
          )}
          {item.type === "state" && (
            <IconMapPin className="mr-2" />
          )}
          {item.type === "district" && (
            <IconMapPins className="mr-2" />
          )}
          {item.type === "nb" && (
            <IconBuildingCommunity className="mr-2" />
          )}
          {item.type === "tower" && (
            <IconBuilding className="mr-2" />
          )}
          {item.value}
        </li>
      ))}
    </ul>
  );

  const serviceError = showSuggestions && (
    <ul className="suggestion-list hidden lg:block">
      <p className="py-3 text-sm font-normal text-graySoft text-center">
        Se ha presentado un problema, intentelo más tarde
      </p>
    </ul>
  );

  const notResult = notResults && (
    <ul className="suggestion-list hidden lg:block">
      <p className="py-3 text-sm font-normal text-graySoft text-center">
        Lo sentimos, no hay resultados
      </p>
    </ul>
  );

  const handleInputBlur = () => {
    setSearchText("");
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target as Node)
      ) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  

  // New logic individual

  const [openOption, setOpenOption] = useState<null | HTMLElement>(null);
  const open = Boolean(openOption);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenOption(event.currentTarget);
  };
  const handleClose = () => {
    setOpenOption(null);
  };

  const [openOption2, setOpenOption2] = useState<null | HTMLElement>(null);
  const open2 = Boolean(openOption2);
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpenOption2(event.currentTarget);
  };
  const handleClose2 = () => {
    setOpenOption2(null);
  };

  const [marketSelected, setMarketSelected] = useState<string>("on_rent");

  const [typeSelected, setType] = useState<string>("");

  const [showPrice, setShowPrice] = useState(false);
  const openPriceFilter = () => {
    setShowPrice(!showPrice);
  };

  // ASIGNAR NUEVOS FILTROS INDIVIDUALES

  const onChangeMarketType = (type: string) => {
    setMarketSelected(type);
    onHandleChangeTypeMarket(type);
  };

  const onChangePropertyType = (type: string) => {
    setType(type);
    onHandleChangeTypeProperty(type);
    handleClose2();
  };

  const onHandleChangeTypeMarket = (type: string) => {
    const nullableData = {
      ...filters,
      marketplace_type: type || null,
    };
    const dataFilter: FiltersStore = replaceFormParams(nullableData);

    const cleanedFilters = cleanFormParams(dataFilter);
    dispatch(setFiltersAction(cleanedFilters));
    if (Object.keys(cleanedFilters).length > 0) {
      navigate(`?${createSearchParams(cleanedFilters)}`);
    }
    handleClose();
  };

  const onHandleChangeTypeProperty = (type: string) => {
    const nullableData = {
      ...filters,
      type: type || null,
    };
    const dataFilter: FiltersStore = replaceFormParams(nullableData);

    const cleanedFilters = cleanFormParams(dataFilter);
    dispatch(setFiltersAction(cleanedFilters));
    if (Object.keys(cleanedFilters).length > 0) {
      navigate(`?${createSearchParams(cleanedFilters)}`);
    }
    handleClose();
  };

  useEffect(() => {
    const cleanedFilters = cleanFormParams(filters);
    setMarketSelected(cleanedFilters?.marketplace_type);
    setType(cleanedFilters?.type);
  }, [filters]);

  const showPropertyType = (typeSelected: string) => {
    switch (typeSelected) {
      case "house":
        return "Casa";
      case "apto":
        return "Apartamento";
      case "land":
        return "Terreno";
      default:
        return "Tipo de propiedad";
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <>
      <div className="w-full flex justify-center bg-[#F5F5F5] relative z-10">
        <div className="container mx-auto px-6 xl:px-0">
          <div className="w-full flex flex-row justify-between">
            <form className="w-full ">
              <div className="flex flex-nowrap flex-row pt-4 pb-4 items-center md:px-4">
                <div className="relative w-full md:w-2/3 xl:w-1/3 flex">
                  {/* <input type="search" id="search-dropdown" className="block p-2.5 w-full z-20 text-sm text-white  rounded-r-lg focus:ring-blue-500 focus:border-blue-500 " placeholder="Buscar por ciudad..." /> */}
                  <div className="relative w-full">
                    <input
                      value={selectedOptionSearch as string}
                      onChange={handleInputChange}
                      onBlur={handleInputBlur}
                      ref={inputRef}
                      placeholder="Buscar por zona o ciudad"
                      className="input-search hidden lg:block"
                      type="text"
                      onKeyDown={handleKeyDown}
                    />
                    <div
                      onClick={() => {
                        document.body.style.overflowY = "hidden";
                        handleMainModal();
                      }}
                      className="input-search block lg:hidden bg-white"
                    >
                      <p className="text-sm font-normal text-graySoft truncate">
                        {filters?.location_id === null ||
                        filters?.location_id === undefined
                          ? "Buscar por zona o ciudad"
                          : window.localStorage.getItem("city")}
                      </p>
                    </div>

                    {suggestionList}
                    {notResult}
                    {serviceError}
                  </div>
                </div>
                <div className="h-8 bg-gray500 w-[0.5px] mx-3 hidden lg:block" />
                {/* FILTRO TYPE MARKET  */}
                <div className="w-auto px-4 hidden lg:block">
                  <Button
                    id="basic-button"
                    aria-controls={open ? "fade-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    className="filter-link"
                  >
                    {marketSelected === "for_sale" ? "Comprar" : "Alquilar"}
                    <i className={`gg-chevron-down ml-4`}></i>
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={openOption}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    TransitionComponent={Fade}
                    className="float-option"
                  >
                    <MenuItem>
                      <div className="px-4 py-1">
                        <label className="customRadio text-sm text-graySoft">
                          Comprar
                          <input
                            type="radio"
                            value="for_sale"
                            name="selectCuota"
                            defaultChecked={marketSelected === "for_sale"}
                            onChange={(e) => onChangeMarketType(e.target.value)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </MenuItem>

                    <MenuItem>
                      <div className="px-4 py-1">
                        <label className="customRadio text-sm text-graySoft">
                          Alquilar
                          <input
                            type="radio"
                            value="for_rent"
                            name="selectCuota"
                            defaultChecked={marketSelected === "for_rent"}
                            onChange={(e) => onChangeMarketType(e.target.value)}
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </MenuItem>
                  </Menu>
                </div>
                <div className="h-8 bg-gray500 w-[0.5px] mx-3 hidden xl:block" />
                {/* FILTRO TYPE  */}
                <div className="w-auto px-4 hidden xl:block">
                  <Button
                    id="basic-button"
                    aria-controls={open2 ? "fade-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open2 ? "true" : undefined}
                    onClick={handleClick2}
                    className="filter-link"
                  >
                    {showPropertyType(typeSelected)}

                    <i className={`gg-chevron-down ml-4`}></i>
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={openOption2}
                    open={open2}
                    onClose={handleClose2}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                    TransitionComponent={Fade}
                    className="float-option"
                  >
                    <MenuItem>
                      <div className="px-4 py-1">
                        <label className="customRadio text-sm text-graySoft">
                          Casa
                          <input
                            type="radio"
                            value="house"
                            name="selectCuota"
                            defaultChecked={typeSelected === "house"}
                            onChange={(e) =>
                              onChangePropertyType(e.target.value)
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </MenuItem>
                    <MenuItem>
                      <div className="px-4 py-1">
                        <label className="customRadio text-sm text-graySoft">
                          Apartamento
                          <input
                            type="radio"
                            value="apto"
                            name="selectCuota"
                            defaultChecked={typeSelected === "apto"}
                            onChange={(e) =>
                              onChangePropertyType(e.target.value)
                            }
                          />
                          <span className="checkmark"></span>
                        </label>
                      </div>
                    </MenuItem>
                    {
                          (marketSelected === "for_sale" && country === 'sv') && <MenuItem>
                          <div className="px-4 py-1">
                            <label className="customRadio text-sm text-graySoft">
                              Terreno
                              <input
                                type="radio"
                                value="land"
                                name="selectCuota"
                                defaultChecked={typeSelected === "land"}
                                onChange={(e) =>
                                  onChangePropertyType(e.target.value)
                                }
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </MenuItem>
                        }
                  </Menu>
                </div>

                <div className="h-8 bg-gray500 w-[0.5px] mx-3 hidden xl:block" />

                {/* FILTRO RANGE PRICE  */}
                <div className="w-auto px-4 relative hidden xl:block">
                  <Button
                    id="basic-button"
                    aria-haspopup="true"
                    className="filter-link"
                    onClick={openPriceFilter}
                  >
                    {filters.marketplace_type !== undefined
                      ? "Rango de precio"
                      : filters?.marketplace_type === "for_rent"
                      ? "Renta mensual"
                      : "Precio total"}
                    <i className={`gg-chevron-down ml-4`}></i>
                  </Button>
                  {showPrice && <SelectMaxMinPrice onClose={openPriceFilter} />}
                </div>

                <div className="h-8 bg-gray500 w-[0.5px] mx-3 hidden lg:block" />
                {/* FILTRO MORE FILTERS  */}
                <div className="relative pl-4 lg:pl-0 md:mt-0 md:pt-0 text-center flex justify-start items-center lg:px-4">
                  {/* <button type="submit" className="hidden lg:flex relative top-0 right-0 py-2.5 px-4 text-sm font-medium text-white bg-secondary rounded-lg ">Buscar</button> */}
                  <div
                    onClick={() => {
                      document.body.style.overflowY = "hidden";
                      handleModal("Más filtros");
                    }}
                    className="cursor-pointer relative flex justify-start items-center"
                  >
                    <p className="text-sm font-medium text-primary hidden xl:block">
                      Ver más filtros
                    </p>{" "}
                    <p className="text-sm font-medium text-primary block xl:hidden">
                      Filtrar
                    </p>
                    <IconFilter className="ml-2" color="#2FB672" />
                  </div>
                  {/* <div className="select-order">
                    <div className="dropdown flex justify-start items-center mx-2 lg:mx-4">
                      <div
                        className="dropdown-selected border border-gray-200 hidden lg:flex"
                        onClick={handleDropdownClick}
                      >
                        <img
                          className="mr-3"
                          src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/order.svg"
                          alt=""
                        />
                        <p className=" hidden lg:block">
                          {selectedOption || "Ordenar"}
                        </p>
                      </div>
                      <div
                        className=" border border-gray-200 h-12 w-12 rounded-full flex justify-center items-center lg:hidden"
                        onClick={handleDropdownClick}
                      >
                        <img
                          className=""
                          src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/order.svg"
                          alt=""
                        />
                      </div>
                      {isOpen && (
                        <ul className="dropdown-options">
                          {options.map((option, index) => (
                            <li
                              key={index}
                              className={`dropdown-option ${
                                selectedOption === option.name ? "selected" : ""
                              }`}
                              onClick={() =>
                                handleOptionSelect(option.value, option.name)
                              }
                            >
                              {option.name}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="bg-[#F5F5F5]">
        <div className="container mx-auto px-6 xl:px-0">
          <div className="filter-container">
            {filters?.is_new !== null && (
              <span className="px-4 py-1 rounded-sm text-[#424242] bg-[#E0E0E0] font-medium text-xs lg:text-sm flex align-center items-center justify-between w-max cursor-pointer active:bg-gray-300 transition duration-300 ease option-filter mb-3">
                {filters?.is_new ? "Nuevo" : "Usado"}
                <button
                  className="bg-transparent hover focus:outline-none"
                  onClick={() => removeFilter("is_new")}
                >
                  <IconX color="#424242" width={20} className="ml-1" />
                </button>
              </span>
            )}

            {filters?.is_furnished !== null && (
              <span className="px-4 py-1 rounded-sm text-[#424242] bg-[#E0E0E0] font-medium text-xs lg:text-sm flex align-center items-center justify-between w-max cursor-pointer active:bg-gray-300 transition duration-300 ease option-filter mb-3">
                {filters?.is_furnished ? "Amueblado" : "No amueblado"}
                <button
                  className="bg-transparent hover focus:outline-none"
                  onClick={() => removeFilter("is_furnished")}
                >
                  <IconX color="#424242" width={20} className="ml-1" />
                </button>
              </span>
            )}

            {filters?.has_white_goods !== null && (
              <span className="px-4 py-1 rounded-sm text-[#424242] bg-[#E0E0E0] font-medium text-xs lg:text-sm flex align-center items-center justify-between w-max cursor-pointer active:bg-gray-300 transition duration-300 ease option-filter mb-3">
                {filters?.has_white_goods ? "Linea blanca" : "Sin linea blanca"}
                <button
                  className="bg-transparent hover focus:outline-none"
                  onClick={() => removeFilter("has_white_goods")}
                >
                  <IconX color="#424242" width={20} className="ml-1" />
                </button>
              </span>
            )}

            {filters?.min_price > 0 && (
              <span className="px-4 py-1 rounded-sm text-[#424242] bg-[#E0E0E0] font-medium text-xs lg:text-sm flex align-center items-center justify-between w-max cursor-pointer active:bg-gray-300 transition duration-300 ease option-filter mb-3">
                Mínimo: ${filters?.min_price?.toLocaleString()}
                <button
                  className="bg-transparent hover focus:outline-none"
                  onClick={() => removeFilter("min_price")}
                >
                  <IconX color="#424242" width={20} className="ml-1" />
                </button>
              </span>
            )}

            {filters?.max_price > 0 && (
              <span className="px-4 py-1 rounded-sm text-[#424242] bg-[#E0E0E0] font-medium text-xs lg:text-sm flex align-center items-center justify-between w-max cursor-pointer active:bg-gray-300 transition duration-300 ease option-filter mb-3">
                Máximo: ${filters?.max_price?.toLocaleString()}
                <button
                  className="bg-transparent hover focus:outline-none"
                  onClick={() => removeFilter("max_price")}
                >
                  <IconX color="#424242" width={20} className="ml-1" />
                </button>
              </span>
            )}

            {filters?.type && (
              <span className="px-4 py-1 rounded-sm text-[#424242] bg-[#E0E0E0] font-medium text-xs lg:text-sm flex align-center items-center justify-between w-max cursor-pointer active:bg-gray-300 transition duration-300 ease option-filter mb-3">
                {showPropertyType(filters?.type)}
                <button
                  className="bg-transparent hover focus:outline-none"
                  onClick={() => removeFilter("type")}
                >
                  <IconX color="#424242" width={20} className="ml-1" />
                </button>
              </span>
            )}

            {filters?.rooms > 0 && (
              <span className="px-4 py-1 rounded-sm text-[#424242] bg-[#E0E0E0] font-medium text-xs lg:text-sm flex align-center items-center justify-between w-max cursor-pointer active:bg-gray-300 transition duration-300 ease option-filter mb-3">
                {filters?.rooms} Habitaciones
                <button
                  className="bg-transparent hover focus:outline-none"
                  onClick={() => removeFilter("rooms")}
                >
                  <IconX color="#424242" width={20} className="ml-1" />
                </button>
              </span>
            )}

            {filters?.bathrooms > 0 && (
              <span className="px-4 py-1 rounded-sm text-[#424242] bg-[#E0E0E0] font-medium text-xs lg:text-sm flex align-center items-center justify-between w-max cursor-pointer active:bg-gray-300 transition duration-300 ease option-filter mb-3">
                {filters?.rooms} Baños
                <button
                  className="bg-transparent hover focus:outline-none"
                  onClick={() => removeFilter("bathrooms")}
                >
                  <IconX color="#424242" width={20} className="ml-1" />
                </button>
              </span>
            )}

            {filters?.parking_spots > 0 && (
              <span className="px-4 py-1 rounded-sm text-[#424242] bg-[#E0E0E0] font-medium text-xs lg:text-sm flex align-center items-center justify-between w-max cursor-pointer active:bg-gray-300 transition duration-300 ease option-filter mb-3">
                {filters?.parking_spots} Parqueos
                <button
                  className="bg-transparent hover focus:outline-none"
                  onClick={() => removeFilter("parking_spots")}
                >
                  <IconX color="#424242" width={20} className="ml-1" />
                </button>
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterBar;
