import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import arrow_button from "../../assets/icons/arrow_button.svg";
import { fetchResponseAdapter } from '../../adapters/fetchAdapter';
import FormControlInfo from '../../components/Controls/FormControlInfo';
import { getAuthToken, unsetAuth } from '../../helpers/authHelper';
import useCallApiAndLoad from '../../hooks/useCallApiAndLoad';
import { logoutAction } from '../../redux/features/auth';
import { AppStore } from '../../redux/store';
import CardInfoPay from './components/CardInfoPay';
import { CurrentTransaction, PaymentDetail } from './models/investorModel';
import { getInvestorPaymentDetailEndpoint, getInvestorPrecheckoutEndpoint } from './services/investorService';

const InvestorPaymentDetail = () => {
  const { country } = useSelector((store: AppStore) => store.country);
  const user = useSelector((store: AppStore) => store.auth);
  const token = getAuthToken(user);
  const { id = "" } = useParams();
  const [data, setData] = useState<PaymentDetail>();
  const [selected, setSelected] = useState<string[]>([]);
  const [currentTransaction, setCurrentTransaction] = useState<CurrentTransaction>();
  const { callEndpoint } = useCallApiAndLoad();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const loadPaymentDetail = async () => {
      const { status, data } = await callEndpoint(
        getInvestorPaymentDetailEndpoint(token, id)
      );
      if (status === 401) {
        dispatch(logoutAction({}));
        unsetAuth();
        return navigate(`/app/${country}/auth/login`);
      }
      if (status === 200) {
        const response = fetchResponseAdapter(data);
        setData(response.data);
      }
    };
    loadPaymentDetail()
      .catch((e) => console.error(e));
  }, []);

  const onSelect = async(tDetailId: string) => {
    const { status, data } = await callEndpoint(
      getInvestorPrecheckoutEndpoint(id, tDetailId, token)
    );
    if (status === 401) {
      dispatch(logoutAction({}));
      unsetAuth();
      return navigate(`/app/${country}/auth/login`);
    }
    if (status === 200) {
      const response = fetchResponseAdapter(data);
      setCurrentTransaction(response.data);
    }
    if (selected.includes(tDetailId)) {
      setSelected([]);
      setCurrentTransaction(undefined);
    } else {
      setSelected([tDetailId]);
    }
  }

  return (
    <>
      <div className="container">
        <div className="w-full pb-10 md:pb-24 pt-24">
          <p className="w-full text-center mt-6 text-third text-lg">
            Control de pagos y detalles
          </p>
          <h1 className="w-full my-6 text-third text-4xl font-bold text-center">
            Detalle de unidad
          </h1>
          <div className="w-full max-w-[960px] mx-auto">
            <div className="pb-10">
              <div className="w-full px-4 lg:px-0 flex justify-center mx-auto mt-12">
                <CardInfoPay transactionInfo={data?.transactionInfo} />
              </div>
            </div>
            <div className="w-full flex flex-wrap lg:flex-nowrap justify-between items-start gap-5 px-4 md:px-0">
              <div className="w-full lg:w-1/2 lg:pr-6 border border-tea-500 rounded-lg px-5 py-6">
                <p className="text-blueForm text-base font-semibold tracking-tight">
                  Detalle de pagos
                </p>
                <div className="w-full flex flex-wrap justify-between items-start bg-tea-100 px-4 py-1 rounded-lg mt-4">
                  {data?.transactionDetail.map((transaction, index) => (
                    <div key={`td-${transaction}`} className="w-full flex justify-between my-3">
                      <div className="flex">
                        <div className="mr-10">
                          <label
                            className="customRadio text-sm text-graySoft"
                            data-variation={(!transaction.is_selectable) ? "disabled" : ""}
                          >
                            {`${transaction.label}`}
                            <input
                              type="radio"
                              disabled={!transaction.is_selectable}
                              value={transaction.id}
                              name="selectCuota"
                              onChange={(e) => onSelect(e.target.value)}
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                        <p
                          className={`text-sm font-normal text-graySoft ${!transaction.is_selectable ? " opacity-40 " : ""
                            }`}
                        >
                          Por pagar
                        </p>
                      </div>
                      <div>
                        <p
                          className={`text-sm font-normal text-primary ${!transaction.is_selectable ? " opacity-40 " : ""
                            } `}
                        >
                          {transaction.value}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
                <hr className=" w-full border-tea-500 my-4" />
                <div className="px-4">
                  <p className="text-blueForm text-base font-semibold tracking-tight">
                    Cuotas pagadas
                  </p>
                  {data?.transactionDetailPaid.map(month => (
                    <div key={`transactionDetailPaid-${month}`}
                      className="w-full flex justify-between my-3">
                      <div className="flex">
                        <div className="mr-10">
                          <p className=" text-sm text-graySoft">{`${month.label} ${month.due_date}`}</p>
                        </div>
                        <p className="text-sm font-normal text-graySoft">
                          Pagada
                        </p>
                      </div>
                      <div>
                        <p className="text-sm font-normal text-graySoft">{month.value}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-full my-8 lg:my-0 lg:w-1/2 border border-tea-500 rounded-md p-4">
                <div className="w-full py-4 border-b border-tea-500">
                  <p className="text-blueForm text-base font-semibold">
                    Detalle a cancelar
                  </p>
                </div>
                {currentTransaction ? (
                    <>
                      <div className="w-full py-4 border-b border-tea-500">
                        {currentTransaction?.preCheckout.map((item: any) =>
                          item.is_total ? (
                            <div className="w-full mb-2 text-graySoft flex justify-between">
                              <span className="text-blueForm font-bold">
                                {item.label}
                              </span>
                              <span className="text-blueForm font-bold">
                                {item.value}
                              </span>
                            </div>
                          ) : (
                            <div className="w-full mb-2 text-graySoft flex justify-between">
                              <span>{item.label}</span>
                              <span>{item.value}</span>
                            </div>
                          )
                        )}
                      </div>

                      <div className="mt-4 mb-2">
                        <div className="w-full bg-greenLight px-4 rounded-full py-3 flex justify-between items-center">
                          <span className="text-blueForm font-medium">
                            Subtotal
                          </span>
                          <span className="text-blueForm text-2xl font-bold">
                            {currentTransaction?.total}
                          </span>
                        </div>
                      </div>
                      <div className="flex justify-center">
                        <Link
                          to={`/app/${country}/l/ld/payment-detail/${id}/${selected[0]}`}
                          onClick={() => {
                            window.scrollTo({
                              top: 0,
                              left: 0,
                              behavior: "smooth",
                            });
                          }}
                          className="bg-orange py-2 pl-8 pr-2 w-full text-white text-base rounded-full flex justify-end items-center group mt-2"
                        >
                          <span className="mx-auto">Continuar</span>
                          <img
                            className="inline group-hover:-translate-x-3 duration-300 ease-in-out"
                            src={arrow_button}
                            alt=""
                          />
                        </Link>
                      </div>
                    </>
                  ) : (
                    <div className="my-4 text-sm text-graySoft">
                      {" "}
                      Selecciona una cuota para ver el detalle de pago{" "}
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InvestorPaymentDetail