import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAuth, setAuthDeveloper, unsetAuth } from "../helpers/authHelper";
import useCallApiAndLoad from "../hooks/useCallApiAndLoad";
import { loginAction, logoutAction } from "../redux/features/auth";
import { AppStore } from "../redux/store";
import { logoutEndpoint } from "../services/publicService";
import { useState } from "react";
import { getDataDeveloper, loginEndpoint } from "../pages/Login/services/loginService";
import { fetchResponseAdapter } from "../adapters/fetchAdapter";
import { getAuthUserAdapter } from "../adapters/userAdapter";
import { mapApiCodeMessage } from "../pages/Login/helpers/responseHelper";
import { loginActionDeveloper } from "../redux/features/authDeveloper";

const useAuthServices = () => {
  const { country } = useSelector((store: AppStore) => store.country);
  const user = useSelector((store: AppStore) => store.auth);
  const { callEndpoint } = useCallApiAndLoad();
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const handleLogin = async (dataUser: any) => {
    setLoading(true);
    const { username, password } = dataUser;
    const { status, data } = await callEndpoint(
      loginEndpoint(username, password)
    );

    if (status === 200) {
      setLoading(false);
      const response = fetchResponseAdapter(data);
      const responseData = response.data;
      const authData = getAuthUserAdapter({
        first_name: responseData.name,
        access_token: responseData.access_token,
        refresh_token: responseData.refresh_token,
        realm: responseData.realm,
        avatar: responseData.avatar,
        name: responseData.name,
        realm_roles: responseData?.realm_roles,
      });

      // Descomentar estas líneas si se desea gestionar el estado de autenticación
      if (responseData?.realm_roles[0] === 'developer_bank_dashboard') {

        const developerResponse = await callEndpoint(
          getDataDeveloper(responseData.access_token)
        );

        if (developerResponse.status === 200) {

          dispatch(loginActionDeveloper(developerResponse.data));
          setAuthDeveloper(developerResponse.data)
          navigate(`/app/${country}/desarrolladoras/proyectos`);
        } else {
          console.log('Error al obtener información del desarrollador');
        }
      }
      dispatch(loginAction(authData));
      setAuth(authData);
      navigate(`/app/${country}/desarrolladoras/proyectos`);
    } else {
      setLoading(false);
      const { code } = data;
      const message = mapApiCodeMessage(code);
    }
  };

  const handleLogout = async () => {
    const { token, rft, realm } = user;
    await callEndpoint(logoutEndpoint(token, rft, realm));
    dispatch(logoutAction({}));
    unsetAuth();
    navigate(`/app/${country}/auth/desarrolladoras/login`);
  };

  return {
    handleLogout,
    handleLogin,
    loading,
  };
};

export default useAuthServices;
