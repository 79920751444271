import { Link } from "react-router-dom";
//Assets
import logo from "../../assets/images/iso_logo.svg";
import { useSelector } from "react-redux";
import { AppStore } from "../../redux/store";

const ChooseRegistration = () => {
  const {country} = useSelector((state: AppStore) => state.country);

  return (
    <>
      <section className="py-8 bg-tea h-auto md:h-screen flex flex-col justify-center">
        <div className="text-center mb-6">
          <img className="inline-block w-16" src={logo} alt="Logo PropiLatam" />
        </div>
        <h1 className="text-blueForm font-medium text-2xl text-center mb-4">
          ¡Regístrate!
        </h1>

        <h5 className="text-graySoft font-medium text-sm text-center">
          Elige tu tipo de cuenta:
        </h5>

        <section className="my-8">
          <div className="container mx-auto">
            <div className="w-full flex justify-center items-center px-6 flex-wrap lg:flex-nowrap">
              <div className="my-4 lg:my-0 lg:ml-0 lg:mx-2 lg:w-1/2 flex justify-end ">
                <Link
                  to={`/app/${country}/auth/registration/tn`}
                  className="hover:scale-[1.03] lg:w-3/5"
                >
                  <img
                    src={"https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/registersoyinquilino.png"}
                    alt=""
                    className="w-full hover:scale-[1.03] hover:ease-in ease-in duration-300 hover:duration-300 hover:shadow-md  hover:rounded-3xl"
                  />
                </Link>
              </div>
              <div className=" lg:mr-0 lg:mx-2 lg:w-1/2 flex lg:justify-start">
                <Link
                  to={`/app/${country}/auth/registration/ld`}
                  className="hover:scale-[1.03] lg:w-3/5"
                >
                  <img
                    src={"https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/registersoypropietario.png"}
                    alt=""
                    className="w-full  hover:scale-[1.03] hover:ease-in ease-in duration-300 hover:duration-300 hover:shadow-md  hover:rounded-3xl"
                  />
                </Link>
              </div>
            </div>
          </div>
        </section>
      </section>
      <footer className="bg-tea py-8">
        <span className="text-base text-bluedark">© 2023 Propi</span>
      </footer>
    </>
  );
};
export default ChooseRegistration;
