import { useEffect, useState } from "react";
import { AxiosCall } from "../models/axiosModel";
import { fetchRequestAdapter } from "../adapters/fetchAdapter";
import { AxiosResponse } from "axios";
import { customDelay } from "../helpers/authHelper";
import useUserCountry from "./useUserCountry";

const useCallApiAndLoad = (delayMilliseconds: number = 0) => {
  const [isLoading, setIsLoading] = useState(false);
  const { getServiceUrlCountry } = useUserCountry();
  let controller = new AbortController();

  // used for testing

  const callEndpoint = async (axiosCall: AxiosCall<any>) => {
    setIsLoading(true);
    let result: AxiosResponse;
    let response;
    try {
      if (delayMilliseconds > 0) {
        await customDelay(delayMilliseconds); // used for testing
      }
      const urlService = getServiceUrlCountry();
      if (!urlService) {
        response = fetchRequestAdapter(
          getDefautlErrorWithMessage(
            "User does not have permission for country service",
            1
          )
        );
      } else {
        result = await axiosCall.call(urlService); // config data headers request status statusMessage
        response = fetchRequestAdapter(result); // status, statusText, data
      }
    } catch (e: any) {
      setIsLoading(false);
      if (e.code === "ERR_NETWORK") {
        response = fetchRequestAdapter(
          getDefautlErrorWithMessage("Connection Error!")
        );
      } else {
        response = fetchRequestAdapter(e.response); // status, statusText, data
      }
    }
    setIsLoading(false);
    return response;
  };

  const getDefautlErrorWithMessage = (
    message: string = "General Error.",
    code: number = 0
  ) => {
    return {
      data: {
        code,
        message,
        data: {},
      },
      status: 0,
      statusText: message,
    };
  };

  useEffect(() => {
    // TODO test this
    return () => {
      setIsLoading(false);
      controller && controller.abort();
    };
  }, []);

  return {
    isLoading,
    callEndpoint,
  };
};

export default useCallApiAndLoad;
