// eslint-disable-next-line
// lib
import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { ReactNode, useLayoutEffect } from "react";

// header and footer
import NavBar from "../components/NavBar/NavBar";
import FooterComponent from "../components/Footer";
// rendering pages
import Properties from "../pages/Properties/Properties";
import CheckLandlordType from "../pages/Landlord/CheckLandlordType";
import LandlordProfile from "../pages/Landlord/LandlordProfile";
import BankInfo from "../pages/Landlord/BankInfo";
import AdminProperty from "../pages/Properties/AdminProperty";
import PropertyDetail from "../pages/Properties/PropertyDetail";
import UpdateBankInfo from "../pages/Landlord/UpdateBankInfo";
import UpdateLandlordProfile from "../pages/Landlord/UpdateLandlordProfile";
import TenantProfile from "../pages/Tenant/TenantProfile";
import UpdateTenantProfile from "../pages/Tenant/UpdateTenantProfile";
import TenantOffers from "../pages/Tenant/TenantOffers";
import LandlordOffers from "../pages/Landlord/LandlordOffers";
import TenantFavs from "../pages/Tenant/TenantFavs";
import TenantVisits from "../pages/Marketplace/TenantVisits";
import TenantPayments from "../pages/Tenant/TenantPayments";
import TenantPayment from "../pages/Tenant/TenantPayment";
import TenantSignatureComplete from "../pages/Tenant/TenantSignatureComplete";
import TenantPaymentDetail from "../pages/Tenant/TenantPaymentDetail";
import TenantPaymentCompleted from "../pages/Tenant/TenantPaymentCompleted";
import { useDispatch } from "react-redux";
import { toogleAction } from "../redux/features/navigation";
import TenantVisitsSuccess from "../pages/Marketplace/TenantVisitsSuccess";
import VisitProperty from "../pages/Properties/VisitProperty";
import TenantTransferCompleted from '../pages/Tenant/TenantTransferCompleted';
import InvestorPayments from "../pages/Investor/InvestorPayments";
import InvestorPaymentDetail from '../pages/Investor/InvestorPaymentDetail';
import InvestorPaymentCheckout from '../pages/Investor/InvestorPaymentCheckout';
import InvestorPaymentComplete from "../pages/Investor/InvestorPaymentComplete";
import InvestorPrebooking from "../pages/Investor/InvestorPrebooking";
import InvestorBookingDetail from "../pages/Investor/InvestorBookingDetail";
import SalesPayments from "../pages/Tenant/SalesPayment/SalesPayment";
import SalesPaymentDetail from "../pages/Tenant/SalesPayment/SalesPaymentDetail";
import SalesPaymentCheckout from "../pages/Tenant/SalesPayment/SalesPaymentCheckout";
import SalesPaymentCompleted from "../pages/Tenant/SalesPayment/SalesPaymentCompleted";
import SalesTransferCompleted from "../pages/Tenant/SalesPayment/SalesTransferCompleted";
import TenantMyVisits from "../pages/Tenant/TenantVisits";
import AccountTn from "../pages/Account/AccountTn";
import AccountLd from "../pages/Account/AccountLd";
import DocumentsTn from "../pages/Documents/DocumentsTn";
import DocumentsLd from "../pages/Documents/DocumentsLd";
// components

//getting content
type IMainProps = {
  children?: ReactNode;
};

const SiteRoutes = (props: IMainProps) => {
  const location = useLocation();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    // hide navbar on change route
    dispatch(toogleAction(false));
  }, [location]);

  return (
    <>
      {/* We can implement here a nav bar and avoid it in public routes */}
      <NavBar />
      <div className="container mx-auto">
        <Routes>
          {/* Landlord */}
          <Route path="/ld/profile" element={<LandlordProfile />} />
          <Route
            path="/ld/update-profile"
            element={<UpdateLandlordProfile />}
          />
          <Route path="/property/offers/:id" element={<LandlordOffers />} />
          <Route path="/ld/mi-cuenta" element={<AccountLd />} />
          <Route path="/ld/bank-info" element={<BankInfo />} />
          <Route path="/ld/update-bank-info" element={<UpdateBankInfo />} />
          <Route path="/ld/documents" element={<DocumentsLd />} />
          <Route path="/check-type" element={<CheckLandlordType />} />
          {/* Investor */}
          <Route path="/ld/payments" element={<InvestorPayments />} />
          <Route path="/ld/make-payment/:id" element={<InvestorPaymentDetail />} />

          <Route path="/ld/pre-booking" element={<InvestorPrebooking />} />
          <Route path="/ld/pre-booking/:id" element={<InvestorBookingDetail />} />

          <Route
            path="/ld/payment-detail/:transaction_id/:id"
            element={<InvestorPaymentCheckout />}
          />
          <Route
            path="/ld/payment-completed"
            element={<InvestorPaymentComplete />}
          />
          <Route
            path="/ld/transfer-completed"
            element={<InvestorPaymentComplete />}
          />
          {/* Tenant */}
          <Route path="/tn/mi-cuenta" element={<AccountTn />} />
          <Route path="/tn/profile" element={<TenantProfile />} />
          <Route path="/tn/update-profile" element={<UpdateTenantProfile />} />
          <Route path="/tn/offers" element={<TenantOffers />} />
          <Route path="/tn/visits" element={<TenantMyVisits /> } />
          <Route path="/tn/favorites" element={<TenantFavs />} />
          <Route path="/tn/payments" element={<TenantPayments />} />
          <Route path="/tn/documents" element={<DocumentsTn />} />
          <Route path="/tn/make-payment/:id" element={<TenantPayment />} />
          <Route
            path="/tn/payment-detail/:contract_id/:id"
            element={<TenantPaymentDetail />}
          />
          <Route
            path="/tn/payment-completed"
            element={<TenantPaymentCompleted />}
          />
          <Route
            path="/tn/transfer-completed"
            element={<TenantTransferCompleted />}
          />
          <Route
            path="/tn/signature-completed"
            element={<TenantSignatureComplete />}
          />
          <Route path="/tn/visit/:id" element={<TenantVisits />} />
          <Route path="/tn/visit/success" element={<TenantVisitsSuccess />} />
          {/* Property */}
          
          <Route path="/properties" element={<Properties />} />
          <Route path="/admin-property/:id" element={<AdminProperty />} />
          <Route
            path="/admin-property/visits/:id"
            element={<VisitProperty />}
          />
          <Route path="/property/:id" element={<PropertyDetail />} />

          <Route path="/tn/payments-sales" element={<SalesPayments />} />
          <Route path="/tn/make-payment-sales/:id" element={<SalesPaymentDetail />} />
          <Route
            path="/tn/payment-detail-sales/:contract_id/:id"
            element={<SalesPaymentCheckout />}
          />
          <Route
            path="/tn/payment-sales-completed"
            element={<SalesPaymentCompleted />}
          />
          <Route
            path="/tn/transfer-sales-completed"
            element={<SalesTransferCompleted />}
          />
        </Routes>
      </div>
      <FooterComponent />
    </>
  );
};
export default SiteRoutes;
