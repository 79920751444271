import React, { memo, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import moreIcon from "../../../../assets/icons/r-arrow.svg";
import useCallApiAndLoad from "../../../../hooks/useCallApiAndLoad";
import { getDataProjects } from "../../services/devsServices";
import { useDispatch, useSelector } from "react-redux";
import { AppStore } from "../../../../redux/store";
import { getAuthToken, unsetAuth } from "../../../../helpers/authHelper";
import { logoutAction } from "../../../../redux/features/auth";
import { fetchResponseAdapter } from "../../../../adapters/fetchAdapter";
import { IProject } from "../../models/Devs";

import "./ProjectDetail.scss";
import MainTemplate from "../../layout/MainLayout";
const ProjectList = memo(() => {
  const { country } = useSelector((store: AppStore) => store.country);
  const [data, setData] = useState<Array<IProject>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { callEndpoint } = useCallApiAndLoad();
  const user = useSelector((store: AppStore) => store.auth);
  const dev = useSelector((store: AppStore) => store.authDeveloper);
  const token = getAuthToken(user);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const loadPayments = async () => {
      setIsLoading(true);
      // TODO add new fields from backend and new table
      const { status, data } = await callEndpoint(getDataProjects(token));
      if (status === 401) {
        dispatch(logoutAction({}));
        unsetAuth();
        navigate(`/app/${country}/auth/desarrolladoras/login`);
      }
      if (status === 200) {
        setIsLoading(false);
        const response = fetchResponseAdapter(data);
        // setPayments(response.data.items || [])
        setData(response.data.items);
      }
    };
    loadPayments().catch((e) => console.error(e));
  }, []);

  const goToDetail = (id: string) => {
    if (user?.realm_roles[0] === "developer_bank_dashboard") {
      navigate(`/app/${country}/desarrolladoras/propietarios/proyectos/${id}`);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    } else {
      navigate(`/app/${country}/desarrolladoras/proyectos/${id}`);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <MainTemplate>
      <div className="container">
        <div className="w-full py-24">
          {user?.realm_roles[0] === "developer_bank_dashboard" ? (
            <div className="m-auto w-full px-4 lg:px-0 flex justify-between items-center">
              <div className="w-auto">
              <h1 className="w-full mt-6 mb-2 text-blackGrayScale text-2xl lg:text-3xl font-semibold text-left tracking-wide">
                {user?.name}
              </h1>
              <p className="text-lg text-[#212121]">{dev?.company_role}</p>
              <p className=" text-lg font-medium text-gray600 mb-2">
                Bienvenido, consulta toda la información de tus proyectos
              </p>
              </div>
              <img className="w-40" src={dev?.company_logo} alt="logo bank" />
            </div>
          ) : (
            <div className="m-auto w-full px-4 lg:px-0">
              <h1 className="w-full mt-6 mb-2 text-blackGrayScale text-2xl lg:text-3xl font-semibold text-left tracking-wide">
                Proyectos
              </h1>
              <p className=" text-lg font-medium text-gray600 mb-2">
                Consulta toda la información de tus proyectos
              </p>
            </div>
          )}

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-8 xl:gap-16 mt-20 px-4 lg:px-0">
            {data?.length >= 0 ? (
              data?.map((item: IProject) => {
                return (
                  <div className="relative flex flex-col rounded-xl bg-white bg-clip-border text-gray-700 shadow-lg">
                    <div
                      style={{ backgroundImage: `url(${item.cover})` }}
                      className="relative mx-4 -mt-6 h-40 overflow-hidden rounded-xl bg-clip-border text-white shadow-lg shadow-blue-gray-500/40 bg-gradient-to-r from-blue-500 to-blue-600 bg-cover bg-no-repeat bg-center"
                    ></div>
                    <div className="p-6">
                      <h5 className=" block font-sans text-xl font-semibold leading-snug tracking-normal text-blue-gray-900 antialiased">
                        {item.name}
                      </h5>
                      <span className="text-sm font-semibold text-[#757575] mb-3 block">
                        #{item?.code}
                      </span>
                      <div className="w-full">
                        <p className="text-[#212121] font-semibold text-sm">
                          Fecha de entrega:{" "}
                          <span className="text-[#757575] font-normal">
                            {item.estimate_built_at}
                          </span>{" "}
                        </p>
                        <p className="text-[#212121] font-semibold text-sm">
                          Unidades totales:{" "}
                          <span className="text-[#757575] font-normal">
                            {item.total_units}
                          </span>{" "}
                        </p>
                        <p className="text-[#212121] font-semibold text-sm">
                          Unidades disponibles:{" "}
                          <span className="text-[#757575] font-normal">
                            {item.total_available_units}
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="p-6 pt-0">
                      <button
                        data-ripple-light="true"
                        onClick={() => goToDetail(item?.id)}
                        type="button"
                        className="select-none rounded-lg bg-[#212121] hover:bg-primary py-3 px-6 text-center align-middle font-sans text-xs font-semibold text-white shadow-md shadow-blue-500/20 transition-all hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                      >
                        Ver detalle
                      </button>
                    </div>
                  </div>
                );
              })
            ) : (
              <> No hay disponibles</>
            )}
          </div>
          {/* 
          <div className="w-full my-8 px-4 lg:mt-24 mt-16">
            <ul className="responsive-table">
              <li className="table-header">
                <div className="col col-3">Propiedad</div>
                <div className="col col-1">ID</div>
                <div className="col col-4">Proyecto</div>
                <div className="col col-4">Fecha de entrega</div>
                <div className="col col-4">Unidades totales</div>
                <div className="col col-4">Unidades disponibles</div>
                <div className="col col-1"></div>
              </li>

              {data?.length >= 0 ? (
                data?.map((item: IProject) => {
                  return (
                    <>
                      <li className="table-row">
                        <div
                          style={{ backgroundImage: `url(${item.cover})` }}
                          className="col col-3 bg-cover bg-center bg-no-repeat"
                          data-label="Job Id"
                        ></div>
                        <div
                          className="px-4 col col-1"
                          data-label="Customer Name"
                        >
                          #{item.code}
                        </div>
                        <div className="px-4 col col-4" data-label="Amount">
                          {item.name}
                        </div>
                        <div
                          className="px-4 col col-4"
                          data-label="Payment Status"
                        >
                          {item.estimate_built_at}
                        </div>
                        <div
                          className="px-4 col col-4"
                          data-label="Payment Status"
                        >
                          {item.total_units}
                        </div>
                        <div
                          className="px-4 col col-4"
                          data-label="Payment Status"
                        >
                          {item.total_available_units}
                        </div>
                        <div
                          className="px-4 col col-1"
                          data-label="Payment Status"
                        >
                          <Link
                            to={`/app/${country}/desarrolladoras/proyectos/${item.id}`}
                            onClick={() => {
                              window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: "smooth",
                              });
                            }}
                          >
                            <button className="duration-300 hover:translate-x-4 cursor-pointer text-sm flex justify-center items-center">
                              <img className="ml-3" src={moreIcon} alt="" />
                            </button>
                          </Link>
                        </div>
                      </li>
                      <div className="block lg:hidden px-3 py-3 shadow-md">
                        <div className="w-full px-2 py-3 bg-gray300 rounded-md mb-2">
                          <span className="text-sm font-medium text-grayText">
                            Propiedad
                          </span>
                          <img src={item.cover} alt="" />
                        </div>

                        <div className="w-full px-2 py-3 bg-tea-100 rounded-sm flex justify-between">
                          <div className="w-1/2">
                            <span className="text-sm font-medium text-grayText">
                              ID
                            </span>
                            <p className="text-sm font-normal text-graySoft">
                              #{item.code}
                            </p>
                          </div>
                          <div className="w-1/2">
                            <span className="text-sm font-medium text-grayText">
                              Proyecto
                            </span>
                            <p className="text-sm font-normal text-graySoft">
                              {item.name}
                            </p>
                          </div>
                        </div>

                        <div className="w-full px-2 py-3 bg-white rounded-sm flex justify-between">
                          <div className="w-1/2">
                            <span className="text-sm font-medium text-grayText">
                              Fecha de entrega
                            </span>
                            <p className="text-sm font-normal text-graySoft">
                              {item.estimate_built_at}
                            </p>
                          </div>
                          <div className="w-1/2">
                            <span className="text-sm font-medium text-grayText">
                              Unidades totales
                            </span>
                            <p className="text-sm font-normal text-graySoft">
                              {item.total_units}
                            </p>
                          </div>
                        </div>

                        <div className="w-full px-2 py-3 bg-tea-100 rounded-sm flex justify-between">
                          <div className="w-1/2">
                            <span className="text-sm font-medium text-grayText">
                              Unidades disponibles
                            </span>
                            <p className="text-sm font-normal text-graySoft">
                              {item.total_available_units}
                            </p>
                          </div>
                        </div>
                        <div className="w-full flex justify-center mt-4">
                          <Link
                            to={`/app/${country}/desarrolladoras/proyectos/${item.id}`}
                            onClick={() => {
                              window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: "smooth",
                              });
                            }}
                          >
                            <p className=" text-sm font-medium text-graySoft flex items-center">
                              Ver detalle
                              <img className="ml-3" src={moreIcon} alt="" />
                            </p>
                          </Link>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <> No hay disponibles</>
              )}
            </ul>
          </div> */}
        </div>
      </div>
    </MainTemplate>
  );
});

ProjectList.displayName = "ProjectList";
export default ProjectList;
