import "../../App.css";
import Button from "../../components/Button";
import * as yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { registrationEndpoint } from "./services/RegistrationService";
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";
import { RegisterUser, RegisterUserForm } from "./models/RegistrationModel";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter";
import { useEffect, useState } from "react";
// assets
import logo from "../../assets/images/iso_logo.svg";
import banner from "../../assets/images/registerpropietario.jpg";
import banner2 from "../../assets/images/registerinquilino.jpg";
import { getRealmPath } from "../../helpers/realmHelpers";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// import { listToSelectOptionAdapter } from "../../adapters/listsAdapter";
// import {
//   getCountriesEndpoint,
//   getStatesEndpoint,
// } from "../../services/publicService";
import FormControlError from "../../components/Controls/FormControlError";
import MaskInput from "../../components/Controls/MaskInput";
import { useSelector } from "react-redux";
import { AppStore } from "../../redux/store";

interface Props {
  realm: string;
}

const Registration = (props: Props) => {
  
  const {country} = useSelector((state: AppStore) => state.country);

  const { realm } = props;
  // const [countries, setCountries] = useState<Array<any>>([]);
  // const [states, setStates] = useState<Array<any>>([]);
  let bannerRegistration;

  if (realm === "tenant") {
    bannerRegistration = banner2;
  } else {
    bannerRegistration = banner;
  }
  useEffect(() => {

    const callCatalogs = async () => {
      if (realm === "tenant") {
        bannerRegistration = banner2;
      } else {
        bannerRegistration = banner;
      }
      // if (realm !== "landlord") {
      //   return;
      // }
      // const { status, data } = await callEndpoint(getCountriesEndpoint());
      // if (status !== 200) {
      //   return;
      // }
      // const countriesData = fetchResponseAdapter(data);
      // setCountries(
      //   listToSelectOptionAdapter(
      //     countriesData.data,
      //     "country_id",
      //     "country_name"
      //   )
      // );
    };
    callCatalogs().catch((e) => console.error(e));
  }, []);

  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const [loginError, setLoginError] = useState("");
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm<RegisterUserForm>({
    resolver: yupResolver(
      yup
        .object({
          name: yup.string().required("Nombre es requerido"),
          last_name: yup.string().required("Apellido es requerido"),
          email: yup
            .string()
            .email("Ingrese un formato correcto para correo")
            .required("Email es requerido"),
          password: yup.string().required("Contraseña es requerida"),
          confirm_password: yup
            .string()
            .required("Confirmación de Contraseña es requerida")
            .oneOf(
              [yup.ref("password"), null],
              "Contraseñas deben ser iguales"
            ),
          phone: yup.string()
            .required("Teléfono es requerido")
            .min(9, 'Teléfono debe estar en formato válido') // 9 includes 8 digits plus - 
            .max(9, 'Teléfono debe estar en formato válido')
          // ...(realm === "landlord"
          //   ? {
          //       country: yup.string().required("País es requerido"),
          //       state: yup.string().required("Departamento es requerido"),
          //     }
          //   : {}),
        })
        .required()
    ),
  });

  const doRegister = async (form: RegisterUserForm) => {
    const registration: RegisterUser = {
      username: form.email,
      password: form.password,
      first_name: form.name,
      last_name: form.last_name,
      email: form.email,
      phone: form.phone,
      area_code: "-",
      country: "",
      state: "",
    };
    const { status, data } = await callEndpoint(
      registrationEndpoint(realm, registration)
    );
    if (status === 200) {
      const response = fetchResponseAdapter(data);
      const responseData = response.data;
      const pathRealm = getRealmPath(realm);
      return navigate(`/app/${country}/auth/confirmation/${pathRealm}/${responseData.pid}`, {
        replace: true,
        state: { email: form.email },
      });
    }
    const { code, message } = data;
    setLoginError(`Error(${code}): ${message}`);
  };

  // const handleChangeCountry = async ({ target }: any) => {
  //   const { value = "" } = target;
  //   setStates([]);
  //   if (value) {
  //     const { status, data } = await callEndpoint(getStatesEndpoint(value));
  //     if (status === 200) {
  //       const statesResponse = fetchResponseAdapter(data);
  //       setStates(
  //         listToSelectOptionAdapter(
  //           statesResponse.data,
  //           "state_id",
  //           "state_name"
  //         )
  //       );
  //       return;
  //     }
  //   }
  // };

  return (
    <div className="bg-tea h-full">
      <div className="w-full flex items-start justify-between h-full">
        <div className="w-full px-4 lg:w-1/2 lg:h-screen lg:overflow-y-scroll">
          <div className="pt-16 pb-0 ">
            <div className="text-center mb-6">
              <Link to={`/${country}/`}>
                <img
                  className="inline-block"
                  src={logo}
                  alt="Logo PropiLatam"
                />
              </Link>
            </div>
            <h1 className="text-bluedark font-medium text-2xl text-center ">
              Regístrate
            </h1>
            <div className="text graySoft text-sm text-center mb-8 lg:px-24">
              {realm !== "landlord"
                ? "Para ofrecerte las mejores opciones de alquiler necesitamos que completes los siguientes datos."
                : "Antes de publicar tu propiedad necesitamos que completes los siguientes campos."}
            </div>
          </div>

          <div className="max-w-sm m-auto">
            <form
              id={ realm === 'landlord' ? 'cuenta-creada-propietario' : 'cuenta-creada-inquilino' } 
              onSubmit={handleSubmit(doRegister)} 
              className="login-form">
              <FormControlError message={loginError} />
              <input
                type="text"
                placeholder="Nombres"
                className="bg-white block py-3 px-2 w-full text-sm text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-5"
                {...register("name")}
              />
              <FormControlError message={errors.name?.message} />
              <input
                type="text"
                placeholder="Apellidos"
                className="bg-white block py-3 px-2 w-full text-sm text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-5"
                {...register("last_name")}
              />
              <FormControlError message={errors.last_name?.message} />
              <input
                type="text"
                placeholder="Email"
                className="bg-white block py-3 px-2 w-full text-sm text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-5"
                {...register("email")}
              />
              <FormControlError message={errors.email?.message} />
              {/* {realm === "landlord" ? (
                <>
                  <select
                    {...register("country", {
                      onChange: (e: Event) => handleChangeCountry(e),
                    })}
                    className="bg-white block py-3 px-2 w-full text-sm text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-5"
                  >
                    <option value="">Seleccione un país...</option>
                    {countries.map((c) => (
                      <option value={c.value}>{c.label}</option>
                    ))}
                  </select>
                  <FormControlError message={errors.country?.message} />
                  <select
                    {...register("state")}
                    className="bg-white block py-3 px-2 w-full text-sm text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-5"
                  >
                    <option value="">Seleccione un departamento...</option>
                    {states.map((c) => (
                      <option value={c.value}>{c.label}</option>
                    ))}
                  </select>
                  <FormControlError message={errors.state?.message} />
                </>
              ) : null} */}

              <input
                type="password"
                placeholder="Contraseña"
                className="bg-white block py-3 px-2 w-full text-sm text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-5"
                {...register("password")}
              />
              <FormControlError message={errors.password?.message} />
              <input
                type="password"
                placeholder="Confirmar contraseña"
                className="bg-white block py-3 px-2 w-full text-sm text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-5"
                {...register("confirm_password")}
              />
              <FormControlError message={errors.confirm_password?.message} />

              {/* <input
                type="text"
                placeholder="Número teléfonico"
                className="bg-white block py-3 px-2 w-full text-sm text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-5"
                {...register("phone")}
              /> */}
              <Controller
                control={control}
                name="phone"
                render={({ field: { ref } }) => (
                  <MaskInput
                    ref={ref}
                    register={register}
                    name={"phone"}
                    mask={"9999-9999"}
                    placeholder="Número teléfonico"
                    customClass="bg-white block py-3 px-2 w-full text-sm text-gray-900 border border-grayform appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer mt-5"
                  />
                )}
              />
              <FormControlError message={errors.phone?.message} />
              <Button
                type="submit"
                disabled={isLoading}
                className="relative w-full bg-primary text-white py-5 px-4 my-4 text-base rounded-full flex justify-center items-center"
              >
                Registrarme
              </Button>
            </form>
            <div className="block h-px bg-grayForm w-full my-8"></div>
            <span className="block text-center text-grayFormText">
              ¿Ya tienes una cuenta?
            </span>
            <Link
              to={`/app/${country}/auth/login`}
              className="relative w-full bg-primary text-white py-5 px-4 my-4 text-base rounded-full flex justify-center items-center hover:bg-transparent hover:text-primary border-primary border ease-in-out duration-300 "
            >
              <span className="mr-2">Inicia sesión</span>
            </Link>
          </div>

          <footer className="py-8">
            <span className="text-base text-bluedark">© 2023 Propi</span>
          </footer>
        </div>
        <div className="hidden lg:block h-full lg:w-1/2">
          <img
            src={bannerRegistration}
            className="h-screen object-cover w-full"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default Registration;
