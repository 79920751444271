import React, { memo } from "react";
import { PopularCities } from "../../../../models/PropertyModel";
import CardPopular from "../../../../components/CardPopular/CardPopular";
import './NotFoundProperties.scss'
import { AppStore } from "../../../../redux/store";
import { useSelector } from "react-redux";

interface NotPropertiesProps {
  title: string;
  subtitle: string;
  citiesPopular: PopularCities[];
}

const NotFoundProperties = memo(
  ({ title, subtitle, citiesPopular }: NotPropertiesProps) => {
  const { country } = useSelector((store: AppStore) => store.country);
    return (
      <div className="pt-10 md:py-20 bg-white mx-auto">
        <div className="container mx-auto">
          <div className="w-full flex flex-wrap">
            <div className="w-full">
              <p className="text-graySoft text-xs md:text-base text-center mb-4">
                {subtitle}
              </p>
              <h2 className="text-green800 text-2xl md:text-4xl mb-12 text-center">
                {title}
              </h2>
            </div>
            <div className="w-full flex flex-wrap popular-properties">
              <div className="card-p lg:w-1/3 p-2 flex flex-col gap-4">
                {citiesPopular.length > 0 &&
                  citiesPopular
                    ?.slice(0, 2)
                    .map((item: PopularCities, index: number) => (
                      <CardPopular
                        city_name={item.city_name}
                        location_id={item.location_id}
                        location_type={item.location_type}
                        location_zoom={item.location_zoom}
                        qty={item.qty}
                        url_cover={item.url_cover}
                        key={index}
                      />
                    ))}
              </div>
              <div className="card-p lg:w-1/3 p-2 flex flex-col gap-4">
                {citiesPopular.length > 0 &&
                  citiesPopular
                    ?.slice(2, 4)
                    .map((item: PopularCities, index: number) => (
                      <CardPopular
                        city_name={item.city_name}
                        location_id={item.location_id}
                        location_type={item.location_type}
                        location_zoom={item.location_zoom}
                        qty={item.qty}
                        url_cover={item.url_cover}
                        key={index}
                      />
                    ))}
              </div>
              <div className="card-p lg:w-1/3 p-2 flex flex-col gap-4">
                {citiesPopular.length > 0 &&
                  citiesPopular
                    ?.slice(4, 6)
                    .map((item: PopularCities, index: number) => (
                      <CardPopular
                        city_name={item.city_name}
                        location_id={item.location_id}
                        location_type={item.location_type}
                        location_zoom={item.location_zoom}
                        qty={item.qty}
                        url_cover={item.url_cover}
                        key={index}
                      />
                    ))}
              </div>
            </div>
            <div className="w-full flex justify-center mt-8">
              <a
                href={`/app/${country}/marketplace`}
                className="text-sm lg:text-base mb-8 md:mb-0 border-b ease-in-out duration-300 cursor-pointer hover:translate-x-4 border-orange w-fit  text-orange text-left flex items-center justify-start"
              >
                Ver más propiedades
                <span className="ml-2">
                  <img
                    src="https://storage.googleapis.com/assets-us-east4-propilatam-dev/website-assets/propi-web/arrow-orange.svg"
                    alt=""
                  />
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
NotFoundProperties.displayName = "NotFoundProperties";
export default NotFoundProperties;
