import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// styled compnents
import "../../App.css";
import "./scss/Login.scss";
// form
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
// hooks
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "../../redux/features/auth";
import useCallApiAndLoad from "../../hooks/useCallApiAndLoad";
import { loginEndpoint } from "./services/loginService";
// adapters/helpers
import { getAuthUserAdapter } from "../../adapters/userAdapter";
import { fetchResponseAdapter } from "../../adapters/fetchAdapter";
import {
  customDelay,
  getLastPath,
  removeLastPath,
  setAuth,
} from "../../helpers/authHelper";
// assets
import { LoginForm } from "./models/loginModel";
import FormControlError from "../../components/Controls/FormControlError";
import { mapApiCodeMessage } from "./helpers/responseHelper";
import { AppStore } from "../../redux/store";
import { IconLock, IconMail, IconMailCheck } from "@tabler/icons-react";
import MainButton from "../../components/MainButton/mainButton";

const Login = () => {
  const { country } = useSelector((state: AppStore) => state.country);
  const [loginError, setLoginError] = useState("");
  const { isLoading, callEndpoint } = useCallApiAndLoad();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginForm>({
    resolver: yupResolver(
      yup
        .object({
          username: yup.string().required("Usuario es requerido"),
          password: yup.string().required("Contraseña es requerida"),
        })
        .required()
    ),
  });

  const doLogin = async (props: LoginForm) => {
    setLoginError("");
    const { username, password } = props;
    const { status, data } = await callEndpoint(
      loginEndpoint(username, password)
    );
    if (status === 200) {
      const response = fetchResponseAdapter(data);
      const responseData = response.data;
      const authData = getAuthUserAdapter({
        first_name: responseData.name,
        access_token: responseData.access_token,
        refresh_token: responseData.refresh_token,
        realm: responseData.realm,
        avatar: responseData.avatar,
        name: responseData.name,
        last_name: responseData.last_name
      });
      dispatch(loginAction(authData));
      setAuth(authData);
      const lpath = getLastPath();
      if (lpath) {
        removeLastPath();
        await customDelay(100);
        window.open(lpath, "_self");
      } else {
        if (responseData?.realm === 'tenant') {
          window.open(`/app/${country}/l/tn/mi-cuenta`, "_self");
        } else {
          window.open(`/app/${country}/l/ld/mi-cuenta`, "_self");
        }
        
      }
    } else {
      const { code } = data;
      const message = mapApiCodeMessage(code);
      setLoginError(`Error (${code}): ${message}`);
    }
  };

  const goToCodeAccess = () => {
    window.open(`/app/${country}/auth/request-login-by-code`, '_self')
  }

  return (
    <div className="flex flex-col h-auto lg:h-screen">
      <div className=" flex-1">
        <div className="flex md:flex-row flex-col-reverse h-full">
          <div className="flex flex-col md:w-1/2 justify-center items-center text-flat-dark-blue-500 bg-white">
            <div className="md:w-10/12 md:p-0 py-14 px-8">
              <h1 className="text-3xl lg:text-4xl font-bold">¡Hola, bienvenido!👋🏼</h1>
              <h2 className="font-semibold text-xl mt-4">Con Propi puedes:</h2>
              <ul className=" space-y-12 mt-14 md:w-10/12">
                <li className="flex space-x-10">
                  <img
                    className="w-20 h-20"
                    src="https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/v2/quiero_comprar.svg"
                    alt=""
                  />
                  <div>
                    <p className="font-bold">Encontrar tu nuevo hogar</p>
                    <p className="mt-1 text-[#757575] text-sm leading-tight">
                      Descubre los mejores inmuebles para vivir o invertir. ¡Te
                      apoyamos a construir tu patrimonio!
                    </p>
                  </div>
                </li>
                <li className="flex space-x-10">
                  <img
                    className="w-20 h-20"
                    src="https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/v2/quiero_alquilar.svg"
                    alt=""
                  />
                  <div>
                    <p className="font-bold">Vender mi propiedad</p>
                    <p className="mt-1 text-[#757575] text-sm leading-tight">
                      ¡Haz el proceso 100% digital! Selecciona tus propiedades
                      favoritas, programa tus visitas, oferta y negocia con el
                      propietario y firma el contrato digitalmente.
                    </p>
                  </div>
                </li>
                <li className="flex space-x-10">
                  <img
                    className="w-20 h-20"
                    src="https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/v2/quiero_vender.svg"
                    alt=""
                  />
                  <div>
                    <p className="font-bold">
                      Alquilar mi propiedad y rental management
                    </p>
                    <p className="mt-1 text-[#757575] text-sm leading-tight">
                      ¡Despreocúpate de tu casa o apartamento! Nos encargamos de
                      rentabilizar tus inversiones alquilando al mejor inquilino
                      o vendiendo al mejor precio.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="bg-[#EBF1E8] drop-shadow-none md:w-1/2 flex flex-wrap px-4 py-8 md:py-0 md:px-0">
            <div className="w-full md:w-[400px] m-auto bg-white px-8 py-10 rounded-lg container-form">
              <div className="mb-4">
                <a href={`/${country}`}>
                <img className=" mx-auto w-56" src="https://storage.googleapis.com/assets-us-east4-propilatam/website-assets/propi-web/v2/logo-propi.svg" alt="" />
              </a>
              </div>

              <p className="text-base text-[#757575] font-medium text-left leading-tight">
                Si tienes una cuenta. <br /> Inicia sesión por codigo de acceso
              </p>
              {/* <Link
                to={`/app/${country}/auth/registration`}
                className="relative w-full ease-in-out duration-300 h-[68px] hover:bg-primary hover:text-tea text-primary bg-transparent border border-primary color-primary py-5 px-4 my-4 text-white text-base rounded-full flex justify-center items-center"
              >
                <span className="mr-2">Solicitar c</span>
              </Link> */}
              <div className="mt-4">
                <MainButton background="bg-[#212121]" colorText="text-white" icon={<IconMailCheck color="#fff" />} topic="Recibir en mi correo" onAction={() => goToCodeAccess()} />
              </div>
              
              <div className="divider-login">
                <span className="text-sm font-medium text-[#212121] capitalize">O inicia sesión con tus datos</span>
              </div>

              <form
                id="inicio-de-sesion"
                onSubmit={handleSubmit(doLogin)}
                className="login-form mt-3"
              >
                <div className="relative input-icon">
                  <IconMail color="#BDBDBD" />
                  <input
                    type="text"
                    className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 pl-10"
                    placeholder="Correo electronico"
                    {...register("username")}
                  />
                </div>

                <FormControlError
                  message={errors.username?.message}
                  additionalClass="mb-3"
                />
                <div className="relative input-icon">
                  <IconLock color="#BDBDBD" />
                  <input
                    type="password"
                    className="w-full focus:outline-none mt-1 border border-grayForm rounded-md text-graySoft py-2 px-2 pl-10"
                    placeholder="Contraseña"
                    {...register("password")}
                  />
                </div>

                <FormControlError
                  message={errors.password?.message}
                  additionalClass="mb-3"
                />
                <FormControlError message={loginError} additionalClass="mb-3" />
                <div className="my-4">
                  <Link
                    to={`/app/${country}/auth/request-change-password`}
                    className="block text-primary font-semibold text-left text-sm"
                  >
                    ¿Olvidaste tu contraseña?
                  </Link>
                </div>
                <div className="my-6">
                  <MainButton
                    topic="Iniciar sesión"
                    type="submit"
                    colorText="text-white"
                    background="bg-[#212121]"
                  />
                </div>
                <div className="mt-2">
                  <p className="text-base text-[#757575] font-medium text-center">
                    ¿No tienes una cuenta?{" "}
                    <Link
                      to={`/app/${country}/auth/registration`}
                      className="text-primary"
                    >
                      <span className="mr-2 font-semibold">Regístrate</span>
                    </Link>
                  </p>
                </div>
              </form>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
