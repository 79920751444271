import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { isLoggedIn } from "../../helpers/authHelper";
import { AppStore } from "../../redux/store";
import NavBarLandlord from "./NavBarLandlord";
import NavBarTenant from "./NavBarTenant";
import NavBarMobile from "./NavBarMobile";
import useIsVisibleComponent from "../../hooks/useIsVisibleComponent";


import logo from "../../assets/images/logo_propi.svg";
import logoWhite from "../../assets/images/logo_propi_white.svg";
import hamburger from "../../assets/icons/hamburger.svg";
import hamburger_white from "../../assets/icons/hamburger-white.svg";
import { toogleAction } from "../../redux/features/navigation";

import "./scss/NavBar.scss";
import { DEFAULT_AVATAR } from "../../constants/ProfileConstants";
import NavBarInvestor from './NavBarInvestor';
import OptionDev from "./OptionDev";

type Props = {
  children?: JSX.Element;
};

const NavBarDev = ({ children }: Props) => {
  const { country } = useSelector((store: AppStore) => store.country);
  const user = useSelector((store: AppStore) => store.auth);
  const { visible: showNav } = useSelector(
    (store: AppStore) => store.navigation
  );
  const [showModal, setShowModal] = useState<boolean>(true);
  const dispatch = useDispatch();
  const [showMobile, setShowMobile] = useState(false);
  const isLogged = isLoggedIn(user);
  const navigate = useNavigate();
  const location = useLocation();
  const [navbar, setNavbar] = useState(false);
  const [navbarLogo, setNavbarLogo] = useState(logo);
  const { ref } = useIsVisibleComponent();

  const LogoWhite = () => {
    if (location.pathname === "/" && window.scrollY <= 66) {
      setNavbarLogo(logoWhite);
    } else {
      setNavbarLogo(logo);
    }
  };
  const changeBackground = () => {
    if (location.pathname === "/" && window.scrollY <= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });

  useEffect(() => {
    LogoWhite();
    // adding the event when scroll change Logo
    window.addEventListener("scroll", LogoWhite);
  });

  const doShowNav = () => {
    if (isLogged) {
      dispatch(toogleAction(!showNav));
    } else {
      return navigate(`/app/${country}/auth/login`);
    }
  };

  const getNavBar = () => {
    if (!showNav || !isLogged) {
      return null;
    }
    switch (user.realm) {
      case 'landlord':
        return <NavBarLandlord />
      case 'tenant':
        return <NavBarTenant />
      case 'investor':
        return <NavBarInvestor />
    case 'developer':
        return <OptionDev />
    }
  };

  const toggleMobile = () => {
    try {
      setShowMobile((old) => !old);
      document.body.style.overflow = "scroll";
    } catch (error) {}
  };


  return (
    <div ref={ref}>
      <nav
        className={
          navbar
            ? "normal bg-transparent px-2 sm:px-4 py-2.5 fixed top-0 w-full z-50"
            : "active bg-white shadow-md px-2 sm:px-4 pt-2.5 fixed top-0 w-full z-50"
        }
      >
        <div className="container mx-auto flex flex-wrap justify-between items-center mb-3">
          <a href="/" className="flex items-center">
            <img src={navbarLogo} className="mr-3 h-6 sm:h-9" alt="Logo" />
          </a>
          <div
            className="hidden justify-between items-center w-full md:flex md:w-auto md:order-1 relative"
            id="mobile-menu-4"
          >
            <div className="flex">
              <button
                onClick={doShowNav}
                type="button"
                className={`${user.realm === 'investor' ? 'bg-blueForm' : 'bg-gray-900 '}
                  pl-2 pr-8 w-42 text-sm py-2 text-white font-normal rounded-full flex justify-start items-center group`}
              >
                <img
                  className="rounded-full w-7 h-7"
                  src={isLogged && user.avatar ? user.avatar : DEFAULT_AVATAR}
                  alt=""
                />
                <span className="ml-4">
                  {isLogged ? "Mi Cuenta" : "Iniciar sesión"}
                </span>
              </button>
              <div>{getNavBar()}</div>
            </div>
          </div>
          <div
            className="flex
           lg:hidden justify-end items-center"
          >
            <button onClick={toggleMobile}>
              <img
                className="w-6"
                src={navbar ? hamburger_white : hamburger}
                alt=""
              />
            </button>
          </div>
        </div>
        {children}
      </nav>
      {showMobile && <NavBarMobile onAction={toggleMobile} />}
      
      
    </div>
  );
};

export default NavBarDev;
